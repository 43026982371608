<template>
    <div class="left-menu-box">
        <div v-if="!isCollapse" class="aside-backdrop" @click="$store.dispatch('app/toggleSideBar')"></div>
        <el-aside :class="!isCollapse?'floating':''" style="width: 200px;">
            <el-scrollbar>
                <div class="logo-container">
<!--                    <el-link :href="oneLink" :underline="false">-->
<!--                        <el-image class="logo-image" src="/assets/images/logo/hype.png" fit="cover" />-->
<!--                    </el-link>-->
                    <router-link :to="oneLink" :underline="false">
                        <el-image class="logo-image" src="/assets/images/logo/hype.png" fit="cover" />
                    </router-link>
                </div>
                <el-menu :router="true" :default-active="activeIndex">

                    <template v-for="(item,index) in userRoute">

                        <template v-if="item.children.length" v-for="(cItem,cIndex) in item.children">
                            <el-menu-item :index="`${index}-${cIndex}`" :route="`${item.path}${cItem.path?'/'+cItem.path:''}`" :key="`${index}-${cIndex}`" @click="onMenu">
                                <el-image v-if="!cItem.meta.iconType" :src="cItem.meta.icon"/>
                                <i v-if="cItem.meta.iconType" :class="cItem.meta.icon"></i>
                                <template #title>{{ $t('menu.'+cItem.meta.title) }}</template>
                            </el-menu-item>
                        </template>

                        <template v-else>
                            <el-menu-item :index="index.toString()" :route="`/${item.path}`" :key="`${index}-${cIndex}`" @click="onMenu">
                                <el-image v-if="!item.meta.iconType" :src="item.meta.icon"/>
                                <i v-if="item.meta.iconType" :class="item.meta.icon"></i>
                                <template #title>{{ $t('menu.'+item.meta.title) }}</template>
                            </el-menu-item>
                        </template>

                    </template>

                </el-menu>
            </el-scrollbar>
        </el-aside>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import {getPermissionOneLink} from "@/utils/permission";
    export default {
        name: "LeftMenu",
        computed: {
            ...mapGetters(['sidebar', 'userRoute']),
            isCollapse() {
                return !this.sidebar.opened;
            },
        },
        data(){
            return{
                activeIndex:'0-0',
                oneLink:''
            }
        },
        created() {
            let that = this;
            that.checkRoute();
            // that.oneLink = getPermissionOneLink() || '/'
            that.oneLink = '/'
        },
        methods:{
            checkRoute(){
                let that = this;
                let activeIndex = that.eachRoute();
                that.activeIndex = activeIndex
            },
            eachRoute(){
                let that = this;
                let routeName = that.$route.name
                let activeIndex = ''
                that.userRoute.forEach((item,index)=>{
                    if (item.children.length){
                        item.children.forEach((cItem,cIndex)=>{
                            if (cItem.name == routeName){
                                activeIndex = `${index}-${cIndex}`
                            }
                        })
                    }else{
                        if (item.name == routeName){
                            activeIndex = index
                        }
                    }
                })
                if (!activeIndex){
                    that.userRoute.forEach((item,index)=>{
                        if (item.children.length){
                            item.children.forEach((cItem,cIndex)=>{
                                if (that.$route.path.indexOf(cItem.path)!==-1){
                                    activeIndex = `${index}-${cIndex}`
                                }
                            })
                        }
                    })
                }
                return activeIndex
            },
            onMenu(){
                let that = this;
                if (document.body.clientWidth <= 768 && !that.isCollapse){
                    that.$store.dispatch('app/toggleSideBar')
                }
            }
        },
        watch:{
            $route(to,form){
                let that = this;
                that.checkRoute();
            }
        }
    }
</script>

<style scoped>
    .left-menu-box{ width: 200px;}
    .el-scrollbar{ height: 100%;}
    ::v-deep .el-scrollbar .el-scrollbar__wrap{ overflow-x: hidden!important;}
    .aside-backdrop { position: fixed;left: 0;top: 0;z-index: 98;width: 100%;height: 100vh;background: rgba(73, 73, 73, 0.7);transition: all 300ms ease-in-out; display: none;}
    .el-aside { position: fixed; width: 200px;height: 100vh;background: #fff;transition: all 300ms ease-in-out;}
    .el-aside.floating {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 99;
        border: 1px solid #f8f8f8;
    }
    .el-aside .logo-container {
        max-height: 95px;
        background: #fff;
        padding: 0px 0px 0px 30px;
    }
    .el-aside .logo-image {
        width: 110px;
        margin: 30px auto 20px;
    }
    .el-aside .el-menu {
        border: none;
        --el-menu-item-height: 50px;
    }
    .el-aside .el-menu-item{ display: flex; padding-left: 30px !important; text-transform: capitalize}
    .el-menu-item, .el-submenu__title{ line-height: 50px; height: 50px;}
    .el-menu-item:hover,
    .el-menu-item.is-active { color: #212121; font-weight: bold; background-color: transparent;}
    .el-aside .el-menu-item .el-image {display: flex; align-items: center; margin-right: 15px;width: 20px;}
    ::v-deep .el-aside .el-menu-item .el-image img.el-image__inner{ height: initial!important;}
    @media (max-width: 768px) {
        .aside-backdrop { display: block;}
        .left-menu-box{ width: initial;}
    }
</style>
