/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const jobRoutes = {
    path: '/job',
    component: Layout,
    redirect: '/job/list',
    name:'Job List',
    meta: { title: 'job_list', icon: 'peoples', permissions: ['influencer_job']},
    children: [
        {
            path: 'list',
            component: () => import('@/views/Job/Index'),
            name: 'JobList',
            meta: { title: 'job_list', icon: '/assets/images/svgs/icons/job.svg', permissions: ['influencer_job']},
        },
    ],
};


export default jobRoutes;
