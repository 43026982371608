/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const analysisRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/analysis',
    name:'Analysis List',
    meta: { title: 'analysis', icon: 'peoples', permissions: ['admin_analysis']},
    children: [
        {
            path: 'analysis',
            component: () => import('@/views_admin/Analysis/Index'),
            name: 'AnalysisList',
            meta: { title: 'analysis', icon: '/assets/images/svgs/icons/analysis.svg', permissions: ['admin_analysis']},
        },
    ],
};


export default analysisRoutes;
