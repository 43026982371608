export default {
    message: {
        "password_inconsistency":"密码不一致",
        "required":"必填",
        "required_selection":"必填选项",
        "min_max_alert":"较小的数值不大于较大的数值",
        "please_enter_correct_value":"请输入正确的数值",
        "no_data":"未找到数据",
        "least_one_name":"至少输入一个名称",
        "parameter_unusual":"参数异常，请重新输入",
        "submitted_successfully":"提交成功",
        "submitted_failed":"提交失败",
        "operation_successfully":"操作成功",
        "please_uplaod_file":"请上传档案",
        "success":"成功",
        "failed" :"失败",
        "update_successful":"更新成功",
        "update_failed" :"更新失败",
        "import_success":"导入成功",
        "import_failed" :"导入失败",
        "upload_file_success":"上载成功",
        "upload_file_failed":"上载失败",
        "maximum_size_of_5MB":"档案不能大于5MB",
        "EPI20001":"已存在",
        "loading_failed":"载入失败",
        "select_price":"请选择报价",
        "cannot_repeat":"无法重複",
        "enquiry_not_exit":"查询不存在",
        "preorder_is_exit":"要求已被提交。请不要重複提交",
        "E10006":"Error: 验证码无效",
        "E10007":"Error: 使用者名或密码无效",
        "E10008":"Error: 电子邮件不存在",
        "login_error":"Error: 使用者名或密码无效",
        "old_password_incorrect":"旧密码不正确。",
        "no_approval_permission":"无许可权",
        "please_select_file_to_upload": "请选择要上传的档案",
        "upload_template_format": "上传范本只有XLS, XLSX, CSV 格式!",
        "upload_template_size_5mb": "上传范本大小不超过5MB!",
        "exchange_rate_can_not_be_empty": "汇率不能为空",
        "form_submit_failure":"表单未更改，提交失败",
        "one_must_at_least_check":"至少必须检查",
        "check_on_the_menu":"至少检查选单上的一个选项",
        "customer_selects_at_least_one_item":"客户至少选择一个选项",
        "correct_address_msg":"请输入有效的电邮地址",
        "email_address_msg": "请输入你的电邮地址",
        "tel_already_exist": "电话已存在",
        "email_already_exist": "电邮地址已存在",
        "email_cannot_be_repeated": "电邮地址不能重複",
        "tel_cannot_be_repeated": "电话不能重複",
        "name_cannot_be_repeated": "名字不能重複",
        "tel_and_email_are_required": "需要电话和电邮地址",
        "stakeholder_payee_exsits":"收款人已存在。",
        "template_is_error":"范本错误",
        "osp_company_is_exit":"包装公司已经存在",
        "customer_is_exit":"客户存在",
        "not_need_to_submit":"数据未被修改，不需要提交",
        "insufficient_balance":"馀额不足",
        "max_350_char":"不能超过 350 个字元",
        "max_300_char":"不能超过 300 个字元)",
        "max_255_char":"不能超过 255 个字元",
        "max_80_char":"不能超过 80 个字元",
        "max_50_char":"不能超过 50 个字元",
        "max_20_char":"不能超过 20 个字元",
        "required_while_save_without_details":"没有详细资讯保存时需要，",
        "password_minimum_6_characters":"密码至少为 6 个字元。",
        "pls_8_char":"请使用更强的密码。最少8个字元",
        "pwd_too_low":"密码强度太低。",
        "mission_earn_is_required": "任务奖金不能为空",

        "message_1": "请在继续之前接受我们的条款和条件以及隐私政策。",
        "message_2": "手机号码不存在。请使用其他手机号码登录或进入注册页面。",
        "message_3_1": "请使用有效的手机号码，",
        "message_3_2": "数字是必需的。",
        "message_4": "成功更改密码。",
        "message_5": "你多次输入无效代码。请稍后再试。",
        "message_6": "已成功重新发送验证码。",
        "message_7": "请选择要上传的图片。",
        "message_8": "图像类型必须是.GIF，jpeg，jpg，png，bmp",
        "message_9": "已成功複制到剪贴板！",
        "message_10": "不能与使用者ID相同",
        "message_11": "忘记密码成功。",
        "message_12": "电子邮件不存在。",
        "message_13": "登录成功。",
        "message_14": "重置密码成功。",
        "message_15": "更新成功。",
        "message_16": "需要社交媒体名称",
        "message_17": "创建成功。",
        "message_18":"你需要达到任务要求等级后才可申请参与。详情请查阅“我的资料 > 等级”。",

        "please_retry_later":"请稍后再试",
        "username_not_found":"找不到该用户名稱",
    }
};
