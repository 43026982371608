export default {
    auth: {
        "or": "或",
        "text_1": "請輸入你的手機號碼。",
        "text_2": "使用你的手機號碼登錄",
        "text_3": "還未註冊？",
        "text_4": "立即註冊",
        "text_5": "使用Instagram繼續",
        "text_6": "發揮你的影響力",
        "text_7": "你的Instagram追蹤人數是?",
        "text_8": "使用你的手機號碼註冊",
        "text_9": "已有帳戶?",
        "text_10": "忘記密碼",
        "text_11": "請輸入與Hype帳戶連結的手機號碼",
        "text_12_1": "請輸入",
        "text_12_2": "位驗證碼",
        "text_13": "你已多次輸入錯誤的驗證碼。請稍候再試。",
        "text_14": "你輸入的驗證碼不正確。",
        "text_15_1": "重新發送驗證碼於",
        "text_15_2": "分鐘後。",
        "text_16": "重新發送驗證碼。",
        "text_17": "請輸入新密碼",
        "text_18": "請再次輸入新密碼",
        "text_19": "使用密碼登錄",
        "text_20": "使用一次性密碼登錄",
        "text_21": "忘記你的密碼?",
        "text_22": "請輸入密碼",
        "text_23": "請輸入號碼",
        "text_24": "我們向此手機號碼發送了一個驗證碼：",
        "text_25": "更新忘記了的密碼",
        "text_25_2": "檢查你的電子郵件",
        "text_26": "輸入與Hype帳戶連結的電郵地址",
        "text_27": "你的電子郵件是否已更改?",
        "text_28": "我們已將密碼重置連結發送到你的電郵地址。<br>請點擊重置連結創建新密碼。",
        "text_29": "管理員登入",
        "text_30": "使用您的電子郵件登入",
        "text_31": "登入時遇到問題？",
        "text_32": "重置密碼",
        "text_33": "你的電郵地址",
        "text_34": "你的名字",
        "text_35": "輸入新密碼",
        "text_36": "再次輸入新密碼",
        "text_37": "一次性密碼",
        "text_38": "",
        "text_39": "",
        "text_40": "",
        "text_41": "返回登錄",
        "text_42": "為了進一步的支援，",
        "text_43": "密碼已成功更改。",
    }
};
