import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Sub-menu only appear when children.length>=1
 **/

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['view menu zip', 'manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb (default is true)
    affix: true                  if true, the tag will affix in the tags-view
    noTags                       if true, no show in the tags-view
 *   hidden: Boolean | in menu show
 *   auth: true||false||undefined （undefined||true=>need login）
 *   iconType: true||false||undefined （undefined||false=>img icon）
  }
 **/
/* Router for modules */

import authRoutes from "@/router/modules/auth";
import missionRoutes from "@/router/modules/mission";
import jobRoutes from "@/router/modules/job";
import profileRoutes from "@/router/modules/profile";
import walletRoutes from "@/router/modules/wallet";

/*
* admin route
*/
import authRoutesAdmin from "@/router/modules/admin/auth";
import dashboardRoutes from "@/router/modules/admin/dashboard";
import analysisRoutes from "@/router/modules/admin/analysis";
import roleRoutes from "@/router/modules/admin/role";
import socialMediaRoutes from "@/router/modules/admin/social_media";
import brandRoutes from "@/router/modules/admin/brand";
import missionRoutesAdmin from "@/router/modules/admin/mission";
import userRoutes from "@/router/modules/admin/users";
import vettingJobRoutes from "@/router/modules/admin/vetting_job";
import scanRoutes from "@/router/modules/admin/scan";
import productRoutes from "@/router/modules/admin/product";
import adminProfileRoutes from "@/router/modules/admin/profile";
import transactionRoutes from "@/router/modules/admin/transaction";

export const constantRoutes = [

    ...authRoutes,
    missionRoutes,
    jobRoutes,
    walletRoutes,
    profileRoutes,

    // admin route
    ...authRoutesAdmin,
    dashboardRoutes,
    analysisRoutes,
    socialMediaRoutes,
    brandRoutes,
    missionRoutesAdmin,
    vettingJobRoutes,
    // productRoutes,
    userRoutes,
    roleRoutes,
    scanRoutes,
    adminProfileRoutes,
    transactionRoutes,

    // {
    //     path: '/',
    //     component: () => import('@/views/Index'),
    //     hidden: true,
    //     meta:{auth:false}
    // },
    {
        path: '/',
        component: () => import('@/views/Home/Index'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/page-redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/page-redirect/:path*',
                component: () => import('@/views/RedirectPage/Index'),
            },
        ],
    },
    {
        path: '/redirect',
        component: () => import('@/views/RedirectPage/Auth'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/qr',
        component: () => import('@/views/Auth/QrCode'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/404',
        // redirect: { name: 'Page404' },
        component: () => import('@/views/ErrorPage/404'),
        hidden: true,
        meta:{auth:false}
    },
    { path: '*', redirect: '/404', hidden: true},
    {
        path: '/401',
        component: () => import('@/views/ErrorPage/401'),
        hidden: true,
        meta:{auth:false}
    },
];

export const asyncRoutes = [];

const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    base: process.env.VUE_APP_MIX_PATH,
    routes: constantRoutes,
});

const router = createRouter();

export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;

