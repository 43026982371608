const bankList = [
    {
        "code": "003",
        "name": "STANDARD CHARTERED BANK (HONG KONG) LIMITED",
        "value": "STANDARD CHARTERED BANK (HONG KONG) LIMITED (003)"
    },
    {
        "code": "004",
        "name": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (HSBC)",
        "value": "THE HONGKONG AND SHANGHAI BANKING CORPORATION LIMITED (HSBC) (004)"
    },
    {
        "code": "005",
        "name": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
        "value": "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK (005)"
    },
    {
        "code": "006",
        "name": "CITIBANK, N.A.",
        "value": "CITIBANK, N.A. (006)"
    },
    {
        "code": "007",
        "name": "JPMORGAN CHASE BANK, N.A.",
        "value": "JPMORGAN CHASE BANK, N.A. (007)"
    },
    {
        "code": "008",
        "name": "NATWEST MARTETS PLC",
        "value": "NATWEST MARTETS PLC (008)"
    },
    {
        "code": "009",
        "name": "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED",
        "value": "CHINA CONSTRUCTION BANK (ASIA) CORPORATION LIMITED (009)"
    },
    {
        "code": "012",
        "name": "BANK OF CHINA (HONG KONG) LIMITED",
        "value": "BANK OF CHINA (HONG KONG) LIMITED (012)"
    },
    {
        "code": "015",
        "name": "THE BANK OF EAST ASIA, LIMITED",
        "value": "THE BANK OF EAST ASIA, LIMITED (015)"
    },
    {
        "code": "016",
        "name": "DBS BANK (HONG KONG) LIMITED",
        "value": "DBS BANK (HONG KONG) LIMITED (016)"
    },
    {
        "code": "018",
        "name": "CHINA CITIC BANK INTERNATIONAL LIMITED",
        "value": "CHINA CITIC BANK INTERNATIONAL LIMITED (018)"
    },
    {
        "code": "020",
        "name": "CMB WING LUNG BANK LIMITED.",
        "value": "CMB WING LUNG BANK LIMITED. (020)"
    },
    {
        "code": "022",
        "name": "OVERSEA-CHINESE BANKING CORPORATION LTD.",
        "value": "OVERSEA-CHINESE BANKING CORPORATION LTD. (022)"
    },
    {
        "code": "024",
        "name": "HANG SENG BANK LTD.",
        "value": "HANG SENG BANK LTD. (024)"
    },
    {
        "code": "025",
        "name": "SHANGHAI COMMERCIAL BANK LTD.",
        "value": "SHANGHAI COMMERCIAL BANK LTD. (025)"
    },
    {
        "code": "027",
        "name": "BANK OF COMMUNICATIONS CO., LTD",
        "value": "BANK OF COMMUNICATIONS CO., LTD (027)"
    },
    {
        "code": "028",
        "name": "PUBLIC BANK (HONG KONG) LIMITED",
        "value": "PUBLIC BANK (HONG KONG) LIMITED (028)"
    },
    {
        "code": "035",
        "name": "OCBC WING HANG BANK LIMITED",
        "value": "OCBC WING HANG BANK LIMITED (035)"
    },
    {
        "code": "038",
        "name": "TAI YAU BANK LTD.",
        "value": "TAI YAU BANK LTD. (038)"
    },
    {
        "code": "039",
        "name": "CHIYU BANKING CORPORATION LTD.",
        "value": "CHIYU BANKING CORPORATION LTD. (039)"
    },
    {
        "code": "040",
        "name": "DAH SING BANK, LTD.",
        "value": "DAH SING BANK, LTD. (040)"
    },
    {
        "code": "041",
        "name": "CHONG HING BANK LIMITED",
        "value": "CHONG HING BANK LIMITED (041)"
    },
    {
        "code": "043",
        "name": "NANYANG COMMERCIAL BANK, LTD.",
        "value": "NANYANG COMMERCIAL BANK, LTD. (043)"
    },
    {
        "code": "045",
        "name": "UCO BANK",
        "value": "UCO BANK (045)"
    },
    {
        "code": "046",
        "name": "KEB HANA BANK",
        "value": "KEB HANA BANK (046)"
    },
    {
        "code": "047",
        "name": "MUFG BANK, LTD.",
        "value": "MUFG BANK, LTD. (047)"
    },
    {
        "code": "049",
        "name": "BANGKOK BANK PUBLIC COMPANY LIMITED",
        "value": "BANGKOK BANK PUBLIC COMPANY LIMITED (049)"
    },
    {
        "code": "050",
        "name": "INDIAN OVERSEAS BANK",
        "value": "INDIAN OVERSEAS BANK (050)"
    },
    {
        "code": "052",
        "name": "DBS BANK LTD.",
        "value": "DBS BANK LTD. (052)"
    },
    {
        "code": "054",
        "name": "DEUTSCHE BANK AG",
        "value": "DEUTSCHE BANK AG (054)"
    },
    {
        "code": "055",
        "name": "BANK OF AMERICA, N.A.",
        "value": "BANK OF AMERICA, N.A. (055)"
    },
    {
        "code": "056",
        "name": "BNP PARIBAS",
        "value": "BNP PARIBAS (056)"
    },
    {
        "code": "058",
        "name": "BANK OF INDIA",
        "value": "BANK OF INDIA (058)"
    },
    {
        "code": "060",
        "name": "NATIONAL BANK OF PAKISTAN",
        "value": "NATIONAL BANK OF PAKISTAN (060)"
    },
    {
        "code": "061",
        "name": "TAI SANG BANK LIMITED",
        "value": "TAI SANG BANK LIMITED (061)"
    },
    {
        "code": "063",
        "name": "MALAYAN BANKING BERHAD (MAYBANK)",
        "value": "MALAYAN BANKING BERHAD (MAYBANK) (063)"
    },
    {
        "code": "065",
        "name": "SUMITOMO MITSUI BANKING CORPORATION",
        "value": "SUMITOMO MITSUI BANKING CORPORATION (065)"
    },
    {
        "code": "066",
        "name": "PT. BANK NEGARA INDONESIA (PERSERO) TBK.",
        "value": "PT. BANK NEGARA INDONESIA (PERSERO) TBK. (066)"
    },
    {
        "code": "067",
        "name": "BDO UNIBANK, INC.",
        "value": "BDO UNIBANK, INC. (067)"
    },
    {
        "code": "071",
        "name": "UNITED OVERSEAS BANK LTD.",
        "value": "UNITED OVERSEAS BANK LTD. (071)"
    },
    {
        "code": "072",
        "name": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED",
        "value": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (ASIA) LIMITED (072)"
    },
    {
        "code": "074",
        "name": "BARCLAYS BANK PLC.",
        "value": "BARCLAYS BANK PLC. (074)"
    },
    {
        "code": "076",
        "name": "THE BANK OF NOVA SCOTIA",
        "value": "THE BANK OF NOVA SCOTIA (076)"
    },
    {
        "code": "080",
        "name": "ROYAL BANK OF CANADA",
        "value": "ROYAL BANK OF CANADA (080)"
    },
    {
        "code": "081",
        "name": "SOCIETE GENERALE",
        "value": "SOCIETE GENERALE (081)"
    },
    {
        "code": "082",
        "name": "STATE BANK OF INDIA",
        "value": "STATE BANK OF INDIA (082)"
    },
    {
        "code": "085",
        "name": "THE TORONTO-DOMINION BANK",
        "value": "THE TORONTO-DOMINION BANK (085)"
    },
    {
        "code": "086",
        "name": "086 BANK OF MONTREAL",
        "value": "086 BANK OF MONTREAL (086)"
    },
    {
        "code": "092",
        "name": "CANADIAN IMPERIAL BANK OF COMMERCE",
        "value": "CANADIAN IMPERIAL BANK OF COMMERCE (092)"
    },
    {
        "code": "097",
        "name": "COMMERZBANK AG",
        "value": "COMMERZBANK AG (097)"
    },
    {
        "code": "103",
        "name": "UBS AG, HONG KONG",
        "value": "UBS AG, HONG KONG (103)"
    },
    {
        "code": "106",
        "name": "HSBC BANK USA, N.A.",
        "value": "HSBC BANK USA, N.A. (106)"
    },
    {
        "code": "109",
        "name": "MIZUHO BANK, LTD., HONG KONG BRANCH",
        "value": "MIZUHO BANK, LTD., HONG KONG BRANCH (109)"
    },
    {
        "code": "113",
        "name": "DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK",
        "value": "DZ BANK AG DEUTSCHE ZENTRAL-GENOSSENSCHAFTSBANK (113)"
    },
    {
        "code": "118",
        "name": "WOORI BANK",
        "value": "WOORI BANK (118)"
    },
    {
        "code": "119",
        "name": "PHILIPPINE NATIONAL BANK",
        "value": "PHILIPPINE NATIONAL BANK (119)"
    },
    {
        "code": "128",
        "name": "FUBON BANK (HONG KONG) LIMITED",
        "value": "FUBON BANK (HONG KONG) LIMITED (128)"
    },
    {
        "code": "138",
        "name": "MITSUBISHI UFJ TRUST AND BANKING CORPORATION",
        "value": "MITSUBISHI UFJ TRUST AND BANKING CORPORATION (138)"
    },
    {
        "code": "139",
        "name": "THE BANK OF NEW YORK MELLON",
        "value": "THE BANK OF NEW YORK MELLON (139)"
    },
    {
        "code": "145",
        "name": "ING BANK N.V.",
        "value": "ING BANK N.V. (145)"
    },
    {
        "code": "147",
        "name": "BANCO BILBAO VIZCAYA ARGENTARIA S.A.",
        "value": "BANCO BILBAO VIZCAYA ARGENTARIA S.A. (147)"
    },
    {
        "code": "150",
        "name": "NATIONAL AUSTRALIA BANK, LIMITED",
        "value": "NATIONAL AUSTRALIA BANK, LIMITED (150)"
    },
    {
        "code": "151",
        "name": "WESTPAC BANKING CORPORATION",
        "value": "WESTPAC BANKING CORPORATION (151)"
    },
    {
        "code": "152",
        "name": "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED",
        "value": "AUSTRALIA AND NEW ZEALAND BANKING GROUP LIMITED (152)"
    },
    {
        "code": "153",
        "name": "COMMONWEALTH BANK OF AUSTRALIA",
        "value": "COMMONWEALTH BANK OF AUSTRALIA (153)"
    },
    {
        "code": "161",
        "name": "INTESA SANPAOLO S.P.A.",
        "value": "INTESA SANPAOLO S.P.A. (161)"
    },
    {
        "code": "164",
        "name": "UNICREDIT BANK AG",
        "value": "UNICREDIT BANK AG (164)"
    },
    {
        "code": "165",
        "name": "SVENSKA HANDELSBANKEN AB (PUBL)",
        "value": "SVENSKA HANDELSBANKEN AB (PUBL) (165)"
    },
    {
        "code": "170",
        "name": "THE CHIBA BANK LTD.",
        "value": "THE CHIBA BANK LTD. (170)"
    },
    {
        "code": "178",
        "name": "KBC BANK N.V., HONG KONG BRANCH",
        "value": "KBC BANK N.V., HONG KONG BRANCH (178)"
    },
    {
        "code": "180",
        "name": "WELLS FARGO BANK, N.A., HONG KONG BRANCH",
        "value": "WELLS FARGO BANK, N.A., HONG KONG BRANCH (180)"
    },
    {
        "code": "183",
        "name": "COÖPERATIEVE RABOBANK U.A.",
        "value": "COÖPERATIEVE RABOBANK U.A. (183)"
    },
    {
        "code": "185",
        "name": "DBS BANK LTD., HONG KONG BRANCH",
        "value": "DBS BANK LTD., HONG KONG BRANCH (185)"
    },
    {
        "code": "186",
        "name": "THE SHIZUOKA BANK LTD.",
        "value": "THE SHIZUOKA BANK LTD. (186)"
    },
    {
        "code": "188",
        "name": "THE HACHIJUNI BANK, LTD.",
        "value": "THE HACHIJUNI BANK, LTD. (188)"
    },
    {
        "code": "198",
        "name": "HUA NAN COMMERCIAL BANK, LTD.",
        "value": "HUA NAN COMMERCIAL BANK, LTD. (198)"
    },
    {
        "code": "199",
        "name": "THE SHIGA BANK, LTD.",
        "value": "THE SHIGA BANK, LTD. (199)"
    },
    {
        "code": "201",
        "name": "BANK OF TAIWAN",
        "value": "BANK OF TAIWAN (201)"
    },
    {
        "code": "202",
        "name": "THE CHUGOKU BANK LIMITED",
        "value": "THE CHUGOKU BANK LIMITED (202)"
    },
    {
        "code": "203",
        "name": "FIRST COMMERCIAL BANK",
        "value": "FIRST COMMERCIAL BANK (203)"
    },
    {
        "code": "205",
        "name": "COUTTS & CO. LTD.",
        "value": "COUTTS & CO. LTD. (205)"
    },
    {
        "code": "206",
        "name": "CHANG HWA COMMERCIAL BANK, LTD.",
        "value": "CHANG HWA COMMERCIAL BANK, LTD. (206)"
    },
    {
        "code": "210",
        "name": "NATIXIS",
        "value": "NATIXIS (210)"
    },
    {
        "code": "214",
        "name": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED",
        "value": "INDUSTRIAL AND COMMERCIAL BANK OF CHINA LIMITED (214)"
    },
    {
        "code": "220",
        "name": "STATE STREET BANK AND TRUST COMPANY",
        "value": "STATE STREET BANK AND TRUST COMPANY (220)"
    },
    {
        "code": "221",
        "name": "CHINA CONSTRUCTION BANK CORPORATION",
        "value": "CHINA CONSTRUCTION BANK CORPORATION (221)"
    },
    {
        "code": "222",
        "name": "AGRICULTURAL BANK OF CHINA LIMITED",
        "value": "AGRICULTURAL BANK OF CHINA LIMITED (222)"
    },
    {
        "code": "227",
        "name": "ERSTE GROUP BANK AG",
        "value": "ERSTE GROUP BANK AG (227)"
    },
    {
        "code": "229",
        "name": "CTBC BANK CO., LTD.",
        "value": "CTBC BANK CO., LTD. (229)"
    },
    {
        "code": "230",
        "name": "TAIWAN BUSINESS BANK, LTD.",
        "value": "TAIWAN BUSINESS BANK, LTD. (230)"
    },
    {
        "code": "233",
        "name": "TAIWAN BUSINESS BANK, LTD.",
        "value": "TAIWAN BUSINESS BANK, LTD. (233)"
    },
    {
        "code": "235",
        "name": "HSBC PRIVATE BANK (SUISSE) SA",
        "value": "HSBC PRIVATE BANK (SUISSE) SA (235)"
    },
    {
        "code": "236",
        "name": "CATHAY UNITED BANK COMPANY, LIMITED",
        "value": "CATHAY UNITED BANK COMPANY, LIMITED (236)"
    },
    {
        "code": "237",
        "name": "EFG BANK AG",
        "value": "EFG BANK AG (237)"
    },
    {
        "code": "238",
        "name": "CHINA MERCHANTS BANK CO., LTD.",
        "value": "CHINA MERCHANTS BANK CO., LTD. (238)"
    },
    {
        "code": "239",
        "name": "TAIPEI FUBON COMMERCIAL BANK CO., LTD.",
        "value": "TAIPEI FUBON COMMERCIAL BANK CO., LTD. (239)"
    },
    {
        "code": "241",
        "name": "BANK SINOPAC",
        "value": "BANK SINOPAC (241)"
    },
    {
        "code": "242",
        "name": "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD.",
        "value": "MEGA INTERNATIONAL COMMERCIAL BANK CO., LTD. (242)"
    },
    {
        "code": "243",
        "name": "E.SUN COMMERCIAL BANK, LTD.",
        "value": "E.SUN COMMERCIAL BANK, LTD. (243)"
    },
    {
        "code": "245",
        "name": "TAISHIN INTERNATIONAL BANK CO., LTD.",
        "value": "TAISHIN INTERNATIONAL BANK CO., LTD. (245)"
    },
    {
        "code": "248",
        "name": "HONG LEONG BANK BERHAD",
        "value": "HONG LEONG BANK BERHAD (248)"
    },
    {
        "code": "249",
        "name": "STANDARD CHARTERED BANK HONG KONG BRANCH",
        "value": "STANDARD CHARTERED BANK HONG KONG BRANCH (249)"
    },
    {
        "code": "250",
        "name": "CITIBANK (HONG KONG) LIMITED",
        "value": "CITIBANK (HONG KONG) LIMITED (250)"
    },
    {
        "code": "251",
        "name": "ICICI BANK LIMITED",
        "value": "ICICI BANK LIMITED (251)"
    },
    {
        "code": "254",
        "name": "MELLI BANK PLC",
        "value": "MELLI BANK PLC (254)"
    },
    {
        "code": "258",
        "name": "EAST WEST BANK",
        "value": "EAST WEST BANK (258)"
    },
    {
        "code": "259",
        "name": "BANK OF BARODA",
        "value": "BANK OF BARODA (259)"
    },
    {
        "code": "260",
        "name": "FAR EASTERN INTERNATIONAL BANK",
        "value": "FAR EASTERN INTERNATIONAL BANK (260)"
    },
    {
        "code": "261",
        "name": "AXIS BANK LIMITED",
        "value": "AXIS BANK LIMITED (261)"
    },
    {
        "code": "262",
        "name": "CANARA BANK",
        "value": "CANARA BANK (262)"
    },
    {
        "code": "263",
        "name": "CATHAY BANK",
        "value": "CATHAY BANK (263)"
    },
    {
        "code": "264",
        "name": "LAND BANK OF TAIWAN CO., LTD.",
        "value": "LAND BANK OF TAIWAN CO., LTD. (264)"
    },
    {
        "code": "265",
        "name": "TAIWAN COOPERATIVE BANK, LTD.",
        "value": "TAIWAN COOPERATIVE BANK, LTD. (265)"
    },
    {
        "code": "266",
        "name": "PUNJAB NATIONAL BANK",
        "value": "PUNJAB NATIONAL BANK (266)"
    },
    {
        "code": "267",
        "name": "BANCO SANTANDER S.A.",
        "value": "BANCO SANTANDER S.A. (267)"
    },
    {
        "code": "268",
        "name": "UNION BANK OF INDIA",
        "value": "UNION BANK OF INDIA (268)"
    },
    {
        "code": "269",
        "name": "UNION BANK OF INDIA",
        "value": "UNION BANK OF INDIA (269)"
    },
    {
        "code": "271",
        "name": "INDUSTRIAL BANK OF KOREA",
        "value": "INDUSTRIAL BANK OF KOREA (271)"
    },
    {
        "code": "272",
        "name": "BANK OF SINGAPORE LIMITED",
        "value": "BANK OF SINGAPORE LIMITED (272)"
    },
    {
        "code": "273",
        "name": "SHINHAN BANK",
        "value": "SHINHAN BANK (273)"
    },
    {
        "code": "274",
        "name": "O-BANK CO., LTD",
        "value": "O-BANK CO., LTD (274)"
    },
    {
        "code": "275",
        "name": "BNP PARIBAS SECURITIES SERVICES",
        "value": "BNP PARIBAS SECURITIES SERVICES (275)"
    },
    {
        "code": "276",
        "name": "CHINA DEVELOPMENT BANK",
        "value": "CHINA DEVELOPMENT BANK (276)"
    },
    {
        "code": "277",
        "name": "FIRST ABU DHABI BANK PJSC",
        "value": "FIRST ABU DHABI BANK PJSC (277)"
    },
    {
        "code": "278",
        "name": "BANK J. SAFRA SARASIN AG",
        "value": "BANK J. SAFRA SARASIN AG (278)"
    },
    {
        "code": "307",
        "name": "ABN AMRO BANK N.V.",
        "value": "ABN AMRO BANK N.V. (307)"
    },
    {
        "code": "308",
        "name": "HDFC BANK LIMITED",
        "value": "HDFC BANK LIMITED (308)"
    },
    {
        "code": "309",
        "name": "UNION BANCAIRE PRIVEE, UBP SA",
        "value": "UNION BANCAIRE PRIVEE, UBP SA (309)"
    },
    {
        "code": "316",
        "name": "SKANDINAVISKA ENSKILDA BANKEN AB",
        "value": "SKANDINAVISKA ENSKILDA BANKEN AB (316)"
    },
    {
        "code": "320",
        "name": "BANK JULIUS BAER & CO. LTD.",
        "value": "BANK JULIUS BAER & CO. LTD. (320)"
    },
    {
        "code": "324",
        "name": "CREDIT INDUSTRIEL ET COMMERCIAL",
        "value": "CREDIT INDUSTRIEL ET COMMERCIAL (324)"
    },
    {
        "code": "337",
        "name": "TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD.",
        "value": "TAIWAN SHIN KONG COMMERCIAL BANK CO., LTD. (337)"
    },
    {
        "code": "338",
        "name": "BANK OF CHINA LIMITED, HONG KONG BRANCH",
        "value": "BANK OF CHINA LIMITED, HONG KONG BRANCH (338)"
    },
    {
        "code": "339",
        "name": "CA INDOSUEZ (SWITZERLAND) SA",
        "value": "CA INDOSUEZ (SWITZERLAND) SA (339)"
    },
    {
        "code": "341",
        "name": "ICBC STANDARD BANK PLC",
        "value": "ICBC STANDARD BANK PLC (341)"
    },
    {
        "code": "342",
        "name": "LGT BANK AG",
        "value": "LGT BANK AG (342)"
    },
    {
        "code": "344",
        "name": "MACQUARIE BANK LIMITED",
        "value": "MACQUARIE BANK LIMITED (344)"
    },
    {
        "code": "345",
        "name": "SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD.",
        "value": "SHANGHAI PUDONG DEVELOPMENT BANK CO., LTD. (345)"
    },
    {
        "code": "353",
        "name": "CHINA MINSHENG BANKING CORP., LTD.",
        "value": "CHINA MINSHENG BANKING CORP., LTD. (353)"
    },
    {
        "code": "357",
        "name": "PICTET & CIE (EUROPE) S.A.",
        "value": "PICTET & CIE (EUROPE) S.A. (357)"
    },
    {
        "code": "360",
        "name": "NATWEST MARKETS N.V.",
        "value": "NATWEST MARKETS N.V. (360)"
    },
    {
        "code": "368",
        "name": "CHINA EVERBRIGHT BANK CO., LTD.",
        "value": "CHINA EVERBRIGHT BANK CO., LTD. (368)"
    },
    {
        "code": "371",
        "name": "SUMITOMO MITSUI TRUST BANK, LIMITED",
        "value": "SUMITOMO MITSUI TRUST BANK, LIMITED (371)"
    },
    {
        "code": "372",
        "name": "BANK OF SHANGHAI (HONG KONG) LIMITED",
        "value": "BANK OF SHANGHAI (HONG KONG) LIMITED (372)"
    },
    {
        "code": "374",
        "name": "CIMB BANK BERHAD",
        "value": "CIMB BANK BERHAD (374)"
    },
    {
        "code": "377",
        "name": "INDUSTRIAL BANK CO., LTD",
        "value": "INDUSTRIAL BANK CO., LTD (377)"
    },
    {
        "code": "378",
        "name": "YUANTA COMMERCIAL BANK CO., LTD.",
        "value": "YUANTA COMMERCIAL BANK CO., LTD. (378)"
    },
    {
        "code": "379",
        "name": "MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY",
        "value": "MASHREQ BANK - PUBLIC SHAREHOLDING COMPANY (379)"
    },
    {
        "code": "381",
        "name": "KOOKMIN BANK",
        "value": "KOOKMIN BANK (381)"
    },
    {
        "code": "382",
        "name": "BANK OF COMMUNICATIONS (HONG KONG) LIMITED",
        "value": "BANK OF COMMUNICATIONS (HONG KONG) LIMITED (382)"
    },
    {
        "code": "383",
        "name": "CHINA ZHESHANG BANK CO., LTD.",
        "value": "CHINA ZHESHANG BANK CO., LTD. (383)"
    },
    {
        "code": "384",
        "name": "MORGAN STANLEY BANK ASIA LIMITED",
        "value": "MORGAN STANLEY BANK ASIA LIMITED (384)"
    },
    {
        "code": "385",
        "name": "PING AN BANK CO., LTD.",
        "value": "PING AN BANK CO., LTD. (385)"
    },
    {
        "code": "386",
        "name": "HUA XIA BANK CO., LIMITED",
        "value": "HUA XIA BANK CO., LIMITED (386)"
    },
    {
        "code": "802",
        "name": "HONG KONG SECURITIES CLEARING COMPANY LIMITED",
        "value": "HONG KONG SECURITIES CLEARING COMPANY LIMITED (802)"
    },
    {
        "code": "868",
        "name": "CLS BANK INTERNATIONAL",
        "value": "CLS BANK INTERNATIONAL (868)"
    },
    {
        "code": "387",
        "name": "ZA BANK LIMITED",
        "value": "ZA BANK LIMITED (387)"
    },
    {
        "code": "388",
        "name": "LIVI VB LIMITED",
        "value": "LIVI VB LIMITED (388)"
    },
    {
        "code": "389",
        "name": "MOX BANK LIMITED",
        "value": "MOX BANK LIMITED (389)"
    },
    {
        "code": "390",
        "name": "WELAB BANK LIMITED",
        "value": "WELAB BANK LIMITED (390)"
    },
    {
        "code": "391",
        "name": "FUSION BANK LIMITED",
        "value": "FUSION BANK LIMITED (391)"
    },
    {
        "code": "392",
        "name": "PING AN ONECONNECT BANK (HONG KONG) LIMITED",
        "value": "PING AN ONECONNECT BANK (HONG KONG) LIMITED (392)"
    },
    {
        "code": "393",
        "name": "ANT BANK (HONG KONG) LIMITED",
        "value": "ANT BANK (HONG KONG) LIMITED (393)"
    },
    {
        "code": "395",
        "name": "AIRSTAR BANK LIMITED",
        "value": "AIRSTAR BANK LIMITED (395)"
    }
]

const searchBank = (value) => {
    let tempBank = null
    if (value){
        tempBank = bankList.filter(item => item.value == value)
    }
    return tempBank ? tempBank[0].value : ''
}

export default{
    bankList,
    searchBank
}
