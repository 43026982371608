/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const userRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/users',
    name:'User List',
    meta: { title: 'users', icon: 'peoples', permissions: ['admin_user_list']},
    children: [
        {
            path: 'users',
            component: () => import('@/views_admin/Users/Index'),
            name: 'UserList',
            meta: { title: 'users', icon: '/assets/images/svgs/icons/user.svg', permissions: ['admin_user_list']},
        },
        {
            path: 'users-detail',
            component: () => import('@/views_admin/Users/Detail'),
            name: 'UserDetail',
            hidden: true,
            meta: { title: 'users_detail', permissions: ['admin_user_list']},
        },
        {
            path: 'users-update',
            component: () => import('@/views_admin/Users/Update'),
            name: 'UserUpdate',
            hidden: true,
            meta: { title: 'users_update', permissions: ['admin_user_update']},
        },
        {
            path: 'users-log-detail',
            component: () => import('@/views_admin/Users/LogDetail'),
            name: 'UserLogDetail',
            hidden: true,
            meta: { title: 'users_log_detail'},
        },
        {
            path: 'users-transaction-details',
            component: () => import('@/views_admin/Users/TransactionDetails'),
            name: 'UserTransactionDetails',
            hidden: true,
            meta: { title: 'users_transaction_details'},
        },
        {
            path: 'change-pasword',
            component: () => import('@/views_admin/Users/ChangePassword'),
            name: 'ChangePassword',
            hidden: true,
            meta: { title: 'change_password'},
        },
    ],
};


export default userRoutes;
