export default {
    role: {
        permission_name:"許可權名稱",
        select_all:"全選",

        /*influencer*/
        influencer_mission:'任務',
        influencer_mission_apply:'申請 [操作]',
        influencer_job:"我的通告",
        influencer_profile:"個人資料",
        influencer_invite_friends:"邀請朋友",
        user_submit_instagram_profile:"更新Instagram用戶名稱 [操作]",
        user_instagram_type_personal_handling:"Instagram個人用戶處理",

        /*admin*/
        admin_dashboard:"數據報表",
        admin_analysis:"分析",
        admin_social_media:"社交媒體",
        admin_social_media_list:"社交媒體",
        admin_social_media_create:"社交媒體創建",
        admin_social_media_update:"社交媒體更新 [操作]",
        admin_mission:"任務",
        admin_mission_list:"任務",
        admin_mission_create:"任務創建",
        admin_mission_update:"任務更新",
        admin_job:"審批通告",
        admin_job_list:"審批通告",
        admin_job_update_status:"管理員決策 [操作]",
        admin_brand:"品牌",
        admin_brand_list:"品牌",
        admin_brand_create:"品牌創建",
        admin_brand_update:"品牌更新",
        admin_brand_dropdown_menu:"品牌下拉選單",
        admin_user:"用戶",
        admin_user_list:"用戶",
        admin_user_create:"用戶創建 [操作]",
        admin_user_update:"用戶更新",
        user_scrape_instagram_profile:"Instagram用戶資料抓取 [操作]",
        admin_role:"角色",
        admin_role_list:"角色",
        admin_scan_qr:"掃描二維碼",

        assign_user_role:"分配用戶角色",
        remove_role:"移除角色",
        add_role:"添加角色",
        admin_user_transaction_details:"交易詳情",
        admin_user_banlance_withdraw:"餘額提取",
        admin_user_export:'導出',
        admin_transaction:"Transaction",
        admin_transaction_status:"Transaction Status",
    }
};
