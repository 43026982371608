/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const transactionRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/transaction',
    name:'Transaction List',
    meta: { title: 'transaction_list', icon: 'peoples', permissions: ['admin_transaction']},
    children: [
        {
            path: 'transaction',
            component: () => import('@/views_admin/Transaction/Index'),
            name: 'TransactionList',
            meta: { title: 'transaction_list', icon: '/assets/images/svgs/icons/Ui icons=Wallet.svg', permissions: ['admin_transaction']},
        },
       
    ],
};


export default transactionRoutes;
