export default {
    menu: {
        "home":"Home",
        "mission_list":"Missions",
        "job_list":"My Jobs",
        "profile_list":"Profile",
        "invite_friends":"Invite Friends",
        "wallet_list":"My Wallet",
        "product":"Product",

        //admin
        "dashboard":"Dashboard",
        "analysis":"Analysis",
        "role":"Role",
        "social_media":"Social Media",
        "brand":"Brands",
        "brand_create":"Create Brand Profile",
        "brand_update":"Update Brand Profile",
        "mission":"Missions",
        "mission_create":"Create Mission",
        "mission_update":"Update Mission",
        "mission_detail":"Detail Mission",
        "users":"Users",
        "users_log_detail":"User Operation Log",
        "vetting_job":"Vetting Job",
        "scan_list":"Scan QR Code",
        "users_transaction_details":"Transaction details",
        "transaction_list":"Transaction",
    }
};
