export default {
    menu: {
        "home":"主页",
        "mission_list":"任务",
        "job_list":"我的通告",
        "profile_list":"个人资料",
        "invite_friends":"邀请朋友",
        "wallet_list":"我的钱包",
        "product":"产品",

        //admin
        "dashboard":"数据页面",
        "analysis":"分析报告",
        "role":"角色",
        "social_media":"社交媒体",
        "brand":"品牌",
        "brand_create":"创建品牌资料",
        "brand_update":"更新品牌资料",
        "mission":"任务",
        "mission_create":"创建任务",
        "mission_update":"更新任务",
        "mission_detail":"任务详情",
        "users":"用户",
        "users_log_detail":"使用者操作日志",
        "vetting_job":"审批通告",
        "scan_list":"扫描二维码",
        "users_transaction_details":"交易详情",
        "transaction_list":"交易",
    }
};
