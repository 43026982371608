const getters = {
    sidebar: state => state.app.sidebar,
    language: state => state.app.language,
    size: state => state.app.size,
    device: state => state.app.device,
    visitedViews: state => state.tagsView.visitedViews,
    cachedViews: state => state.tagsView.cachedViews,
    userId: state => state.user.id,
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    user: state => state.user.user,
    introduction: state => state.user.introduction,
    roles: state => state.user.roles,
    permissions: state => state.user.permissions,
    userRoute: state => state.user.userRoute,
    userInfo: state => state.user.userInfo,
    permissionRoutes: state => state.permission.routes,
    addRoutes: state => state.permission.addRoutes,
    common: state => state.common,
    customStatus: state => state.customStatus,
};
export default getters;
