export default {
    auth: {
        "or": "或",
        "text_1": "请输入你的手机号码。",
        "text_2": "使用你的手机号码登录",
        "text_3": "还未注册？",
        "text_4": "立即注册",
        "text_5": "使用Instagram继续",
        "text_6": "发挥你的影响力",
        "text_7": "你的Instagram追踪人数是?",
        "text_8": "使用你的手机号码注册",
        "text_9": "已有帐户?",
        "text_10": "忘记密码",
        "text_11": "请输入与Hype帐户连结的手机号码",
        "text_12_1": "请输入",
        "text_12_2": "位验证码",
        "text_13": "你已多次输入错误的验证码。请稍候再试。",
        "text_14": "你输入的验证码不正确。",
        "text_15_1": "重新发送验证码于",
        "text_15_2": "分钟后。",
        "text_16": "重新发送验证码。",
        "text_17": "请输入新密码",
        "text_18": "请再次输入新密码",
        "text_19": "使用密码登录",
        "text_20": "使用一次性密码登录",
        "text_21": "忘记你的密码?",
        "text_22": "请输入密码",
        "text_23": "请输入号码",
        "text_24": "我们向此手机号码发送了一个验证码：",
        "text_25": "更新忘记了的密码",
        "text_25_2": "检查你的电子邮件",
        "text_26": "输入与Hype帐户连结的电邮地址",
        "text_27": "你的电子邮件是否已更改?",
        "text_28": "我们已将密码重置连结发送到你的电邮地址。<br>请点击重置连结创建新密码。",
        "text_29": "管理员登入",
        "text_30": "使用您的电子邮件登入",
        "text_31": "登入时遇到问题？",
        "text_32": "重置密码",
        "text_33": "你的电邮地址",
        "text_34": "你的名字",
        "text_35": "输入新密码",
        "text_36": "再次输入新密码",
        "text_37": "一次性密码",
        "text_38": "",
        "text_39": "",
        "text_40": "",
        "text_41": "返回登录",
        "text_42": "为了进一步的支援，",
        "text_43": "密码已成功更改。",
    }
};
