/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const vettingJobRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/vetting-job',
    name:'Vetting Job List',
    meta: { title: 'vetting_job', icon: 'peoples', permissions: ['admin_job_list']},
    children: [
        {
            path: 'vetting-job',
            component: () => import('@/views_admin/VettingJob/Index'),
            name: 'VettingJobList',
            meta: { title: 'vetting_job', icon: '/assets/images/svgs/icons/vetting-job.svg', permissions: ['admin_job_list']},
        },
    ],
};


export default vettingJobRoutes;
