/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const brandRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/brand',
    name:'Brand List',
    meta: { title: 'brand', icon: 'peoples', permissions: ['admin_brand_list']},
    children: [
        {
            path: 'brand',
            component: () => import('@/views_admin/Brand/Index'),
            name: 'BrandList',
            meta: { title: 'brand', icon: '/assets/images/svgs/icons/brand.svg', permissions: ['admin_brand_list']},
        },
        {
            path: 'brand-create',
            component: () => import('@/views_admin/Brand/Create'),
            name: 'BrandCreate',
            hidden: true,
            meta: { title: 'brand_create', permissions: ['admin_brand_create']},
        },
        {
            path: 'brand-update',
            component: () => import('@/views_admin/Brand/Update'),
            name: 'BrandUpdate',
            hidden: true,
            meta: { title: 'brand_update', permissions: ['admin_brand_update']},
        },
    ],
};


export default brandRoutes;
