import store from '@/store';
import { asyncRoutes, constantRoutes } from '@/router';
import {getCookies} from "@/utils/auth";

/**
 * @param {Array} value
 * @returns {Boolean}
 * @example see @/views/permission/Directive.vue
 */
export function checkPermission(value) {
    if (value && value instanceof Array && value.length > 0) {
        const permissions = store.getters && store.getters.permissions;
        const requiredPermissions = value;
        const hasPermission = permissions.some(permission => {
            return requiredPermissions.includes(permission);
        });
        return hasPermission;
    } else {
        console.error(`Need permissions! Like v-permission="['manage permission','edit article']"`);
        return false;
    }
}

// 用来控制按钮的显示
export function hasBtnPermission(permission) {
    const myBtns = store.getters.permissions
    return myBtns.indexOf(permission) > -1
}

export function getPermission () {
    let permission = store.getters.permissions || []
    return permission
}

export function getPermissionOneLink () {
    let userRoute = store.getters.userRoute
    if (userRoute?.length){
        let oneLink = userRoute[0].path
        let twoLink = userRoute[0].children?.length?userRoute[0].children[0].path:''
        return oneLink + (twoLink?'/'+twoLink:'');
    }else {
        return '';
    }
}


/*
* 只做一、二级路由处理，有更多自行增加
* router permissions && user permissions && router permissions === user permissions || !router permissions
*/
export function userPermissionRoute (allRouters) {
    let allRoute = allRouters || constantRoutes.concat(asyncRoutes)
    let tempRoute = [];
    if (allRoute?.length){
        allRoute.forEach(item=>{
            if (!item.meta?.hidden && !item.hidden){

                if (item.meta?.permissions||item.children?.length){
                    if (item.children?.length){
                        let iTempRoute = JSON.parse(JSON.stringify(item))
                        let cTempRoute = []
                        item.children.forEach(cItem=>{
                            if (!cItem.meta?.hidden && !cItem.hidden){
                                if (cItem.meta?.permissions){
                                    if (checkPermission(cItem.meta.permissions))
                                        cTempRoute.push(cItem)
                                }else {
                                    cTempRoute.push(cItem)
                                }
                            }
                        })
                        if (cTempRoute.length){
                            iTempRoute.children = cTempRoute
                            tempRoute.push(iTempRoute)
                        }
                    }else{
                        if (checkPermission(item.meta.permissions))
                            tempRoute.push(item)
                    }
                }else{
                    tempRoute.push(item)
                }
            }
        })
    }

    if (tempRoute.length){
        let loginType = getCookies('LoginType')?.loginType == 'admin' ? true : false
        let tempPermissionRoute = []
        tempRoute.forEach(item=>{
            if ( item.path && ( (loginType && item.path.indexOf('admin')!=-1) || !loginType && item.path.indexOf('admin')==-1 ) ){
                tempPermissionRoute.push(item)
            }
        })
        tempRoute = tempPermissionRoute
    }

    // console.log(tempRoute)
    store.dispatch("user/setUserRoute", tempRoute );
}
