import { login, logout, getInfo } from '@/api/auth';
import { isLogged, setLogged, removeToken } from '@/utils/auth';
import router, { resetRouter } from '@/router';
import store from '@/store';

import Vue from 'vue'
import i18n from "@/lang";
Vue.use(i18n)

const state = {
    id: null,
    user: null,
    token: isLogged(),
    name: '',
    avatar: '',
    introduction: '',
    roles: [],
    permissions: [],
    userInfo:null,
    UserData: null,
    userRoute:null
};

const mutations = {
    SET_ID: (state, id) => {
        state.id = id;
    },
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    SET_PERMISSIONS: (state, permissions) => {
        state.permissions = permissions;
    },
    SET_USER_INFO: (state, userInfo) => {
        state.userInfo = userInfo;
    },
    SET_USER_ROUTE: (state, userRoute) => {
        state.userRoute = userRoute;
    },
    UPDATE_DATA(state, payload) {
        state.data = { ...state.data, ...payload }
    },
};

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { email, password } = userInfo;
        return new Promise((resolve, reject) => {
            login({ email: email.trim(), password: password })
                .then(response => {
                    // setLogged('1');
                    setLogged(response.data.token);
                    // console.log('response',response);
                    // i18n.locale = response.data.language == 'zh' ? 'zh' : 'en';
                    // i18n.locale = response.data.default_language ? response.data.default_language : 'en';
                    resolve();
                })
                .catch(error => {
                    console.log(error);
                    reject(error);
                });
        });
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo()
                .then(response => {
                    const { data } = response;
                    // if (!data) {
                    //   reject('Verification failed, please Login again.');
                    // }

                    const { roles, first_name, id } = data;
                    // roles must be a non-empty array
                    // if (!roles || roles.length <= 0) {
                    //   reject('getInfo: roles must be a non-null array!');
                    // }

                    // commit('SET_AVATAR', avatar);
                    // commit('SET_INTRODUCTION', introduction);
                    // commit('SET_PERMISSIONS', permissions);
                    commit('SET_ROLES', roles);
                    commit('SET_NAME', first_name);
                    commit('SET_ID', id);
                    commit('SET_USER_INFO', data);
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // get user info
    // getInfo({ commit, state }) {
    //   return new Promise((resolve, reject) => {
    //     getInfo()
    //       .then(response => {
    //         const { data } = response;
    //
    //         if (!data) {
    //           reject('Verification failed, please Login again.');
    //         }
    //
    //         const { roles, name, avatar, introduction, permissions, language ,id } = data;
    //         // roles must be a non-empty array
    //         if (!roles || roles.length <= 0) {
    //           reject('getInfo: roles must be a non-null array!');
    //         }
    //
    //         commit('SET_ROLES', roles);
    //         commit('SET_PERMISSIONS', permissions);
    //         commit('SET_NAME', name);
    //         commit('SET_AVATAR', avatar);
    //         commit('SET_INTRODUCTION', introduction);
    //         commit('SET_ID', id);
    //         resolve(data);
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // },

    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            logout()
                .then((data) => {
                    commit('SET_TOKEN', '');
                    commit('SET_ROLES', []);
                    commit('SET_PERMISSIONS', []);
                    commit('SET_USER_INFO', {});
                    commit('SET_USER_ROUTE', null);
                    removeToken();
                    resetRouter();
                    resolve(data);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
        });
    },

    // Dynamically modify permissions
    changeRoles({ commit, dispatch }, role) {
        return new Promise(async resolve => {
            // const token = role + '-token';

            // commit('SET_TOKEN', token);
            // setLogged(token);

            // const { roles } = await dispatch('getInfo');

            const roles = [role.name];
            const permissions = role.permissions.map(permission => permission.name);
            commit('SET_ROLES', roles);
            commit('SET_PERMISSIONS', permissions);
            resetRouter();

            // generate accessible routes map based on roles
            const accessRoutes = await store.dispatch('permission/generateRoutes', { roles, permissions });

            // dynamically add accessible routes
            router.addRoutes(accessRoutes);

            resolve();
        });
    },

    setUserPermissions({ commit }, permissions) {
        commit('SET_PERMISSIONS', permissions);
    },

    setUserRoute: ({ commit }, userRoute) => {
        commit('SET_USER_ROUTE', userRoute);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
