/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const adminProfileRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/profile',
    name:'Profile',
    hidden: true,
    meta: { title: 'profile', icon: 'peoples'},
    children: [
        {
            path: 'profile',
            component: () => import('@/views_admin/Profile/Index'),
            name: 'ProfileDetail',
            meta: { title: 'profile_list', icon: '/assets/images/svgs/icons/profile.svg'},
        }

    ],
};


export default adminProfileRoutes;
