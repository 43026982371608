/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const profileRoutes = {
    path: '/profile',
    component: Layout,
    redirect: '/profile/list',
    name:'Profile List',
    meta: { title: 'profile_list', icon: 'peoples', permissions: ['influencer_profile']},
    children: [
        {
            path: '',
            component: () => import('@/views/Profile/Index'),
            name: 'ProfileList',
            meta: { title: 'profile_list', icon: '/assets/images/svgs/icons/profile.svg', permissions: ['influencer_profile']},
        },
        {
            path: 'update',
            component: () => import('@/views/Profile/Update'),
            name: 'ProfileUpdate',
            hidden: true,
            meta: { title: 'profile_update', icon: 'peoples'/*, permissions: ['job_list']*/},
        },
        {
            path: 'connect',
            component: () => import('@/views/Profile/ConnectSocialMedia'),
            name: 'ProfileConnectSocialMedia',
            hidden: true,
            meta: { title: 'profile_connect', icon: 'peoples'},
        },
        {
            path: 'invite',
            component: () => import('@/views/Profile/InviteFriends'),
            name: 'InviteFriends',
            meta: { title: 'invite_friends', icon: '/assets/images/svgs/icons/refer.svg', permissions: ['influencer_invite_friends']},
        },
    ],
};


export default profileRoutes;
