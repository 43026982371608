<template>
    <section class="main-view main-layout">
        <section class="route-view">
            <el-container>

                <left-menu v-if="!isCollapse"></left-menu>

                <el-container>
                    <top-header></top-header>
                    <el-main>
                        <keep-alive :include="cachedViews">
                            <router-view :key="key" />
                        </keep-alive>
                    </el-main>
                </el-container>
            </el-container>
        </section>
    </section>
</template>

<script>
    import LeftMenu from "@/layout/components/LeftMenu";
    import TopHeader from "@/layout/components/TopHeader";
    import {mapGetters} from "vuex";
    export default {
        name: "Layout",
        components:{ LeftMenu, TopHeader },
        computed: {
            ...mapGetters(['sidebar']),
            cachedViews() {
                return this.$store.state.tagsView.cachedViews;
            },
            key() {
                return this.$route.fullPath;
            },
            isCollapse() {
                return !this.sidebar.opened;
            },
        },
        created() {

        }
    }
</script>

<style>
    .el-container{ flex-basis: min-content;}
    .main-layout .el-main{ padding-top: 10px;padding-bottom: 60px;}
    @media (max-width: 560px) {
        .main-layout .el-main{ padding-left: 10px; padding-right: 10px;}
    }
</style>
