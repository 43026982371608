export default {
    page: {
        "my_jobs": "我的通告",
        "current_jobs": "現有通告",
        "mission_id": "任務ID",
        "website": "網站",
        "earn": "賺取:",
        "open_to": "開放給",
        "hk_m": "HK$",
        "withdraw": "餘額提取",
        "withdraw_1": "撤回任務",
        "rejected": "拒絕",
        "attended": "已參與",
        "completed": "已完成",
        "missions": "任務",
        "product": "產品",
        "learn_more": "瀏覽更多",
        "apply_now": "申請",
        "applied": "已申請",
        "expired": "已逾期",
        "cancelled": "已取消",
        "sure": "是的，我確定",
        "no": "不是",
        "yes": "是",
        "alert_message_1": "你肯定要",
        "alert_message_2": "通告?",
        "event_confirm_text_1": "你已成功申請任務",
        "event_confirm_text_2": "你的參考編號：",
        "got_it": "知道了",
        "apply_until_colon": "申請截止日期：",
        "ig_unsuccessful_text_1": "與你的Instagram帳戶連接失敗。",
        "ig_unsuccessful_text_2": "綁定失敗",
        "ig_unsuccessful_text_3": "是個人帳戶，",
        "ig_unsuccessful_text_4": "請查看",
        "instagram": "instagram",
        "instagram_colon": "Instagram:",
        "ig_unsuccessful_text_5": "使用商業或創作者帳戶。",
        "ig_unsuccessful_text_6": "聯絡我們",
        "ig_unsuccessful_text_7": "@ 我們的Whatsapp商業帳號.",
        "your_referral_id": "你的推薦ID：",
        "referred_by": "推薦者ID：",
        "your_details": "你的資料",
        "edit_details": "編輯資料",
        "first_name": "名字",
        "last_name": "姓氏",
        "display_name": "暱稱",
        "gender": "性別",
        "birthday": "生日日期",
        "email": "電郵地址",
        "mobile": "手機號碼",
        "social_media_account": "社交媒體帳戶",
        "instagram_unf": "Instagram",
        "instagram_unf_text_1": "請輸入你的 Instagram 用戶名稱以完成綁定過程。",
        "connect_your_instagram_account": "連接你的Instagram帳戶",
        "username": "用戶名稱@",
        "last_updated_colon": "最後更新:",
        "invalid_username": "用戶名稱無效",

        "recommend_to_connect": "推薦連接你的社交媒體帳戶",
        "please_input_your_ig_username": "請輸入你的 Instagram 用戶名稱以完成綁定過程。",
        "connect": "連接",
        "i_will_connect_later": "我稍後會在我的個人資料中連接",

        "invite_friends": "邀請朋友",
        "invite_people_and_earn": "邀請朋友獲得獎賞",
        "invite_people_earn_text_1": "推薦獎金將添加到您的錢包中，如果你的推薦人：",
        "invite_people_earn_text_2": "- 在Instagram上擁有超過2,000名追蹤者",
        "invite_people_earn_text_3": "- 完成一項任務",
        "invite_people_earn_text_4": "餘額將於14個工作天內在你的錢包中更新。",
        "your_referral_QR_code": "你的推薦二維碼",
        "user_id_colon": "用戶ID:",
        "your_referral_link": "你的推薦連結",
        "your_contact": "你的聯繫方式",
        "type_colon": "類型:",
        "username_colon": "用戶名稱:",

        "wallet": "錢包",
        "your_balance": "你的餘額",
        "hkd": "港幣",
        "transaction_History": "交易紀錄",
        "contact_us": "聯絡我們",

        "brand": "品牌",
        "brands": "品牌",
        "influencers": "網紅",
        "A_Z_of_Brands_Profiles": "品牌資料",
        "brand_placeholder": "輸入你的品牌所在區域 (例如: 越南, 香港, 亞太區)",
        "Create_Brand_Profile": "創建品牌資料",
        "brand_id_colon": "品牌ID:",
        "brand_code_colon": "品牌代號:",
        "active": "活躍中",
        "brand_logo": "品牌標誌",
        "brand_code": "品牌代碼",
        "brand_name": "品牌名稱",
        "brand_mission": "品牌使命",
        "brand_full_name": "品牌全名",
        "brand_banner": "品牌横幅",
        "brand_website": "品牌網站",
        "Brand_IG_1st_Origin": "品牌Instagram 1",
        "Brand_IG_1st_Origin_URL": "品牌Instagram 1 鏈結",
        "Brand_IG_2nd_Origin": "品牌Instagram 2",
        "Brand_IG_2nd_Origin_URL": "品牌Instagram 2 鏈結",
        "Brand_FB_1st_Origin": "品牌Facebook 1",
        "Brand_FB_1st_Origin_URL": "品牌Facebook 1 鏈結",
        "Brand_FB_2nd_Origin": "品牌Facebook 2 鏈結",
        "Brand_FB_2nd_Origin_URL": "品牌Facebook 2 鏈結",
        "Brand_Short_description1": "品牌簡介",
        "Brand_Long_description1": "品牌詳細說明 1",
        "Brand_Long_description2": "品牌詳細說明 2",
        "Brand_Long_description3": "品牌詳細說明 3",
        "Brand_Long_description4": "品牌詳細說明 4",
        "Brand_User_define_Field_1": "Brand User define Field 1",
        "Brand_User_define_Field_2": "Brand User define Field 2",
        "Brand_User_define_Field_3": "Brand User define Field 3",
        "last_update_by_colon": "最後更新者：",
        "update_at_colon": "更新於:",
        "created_at_colon": "創建時間：",
        "created_by_colon": "創建者：",
        "update_by_colon": "更新者：",

        "assign_influencers": "分配網紅",
        "mission_type": "任務類型",
        "event_name": "任務名稱",
        "no_of_participants": "參與人數",
        "mission_short_description": "任務簡介",
        "mission_long_description1": "任務詳細說明 1",
        "mission_long_description2": "任務詳細說明 2",
        "mission_long_description1_placeholder": "吸引的活動內容，例如：禮品包、講座、無限暢飲等",
        "mission_start_date": "任務開始日期",
        "select_start_date": "選擇開始日期",
        "mission_start_time": "任務開始時間",
        "select_start_time": "選擇開始時間",
        "mission_end_date": "任務結束日期",
        "select_end_date": "選擇結束日期",
        "mission_end_time": "任務結束時間",
        "select_end_time": "選擇結束時間",
        "event_address1": "活動地址 1",
        "event_address2": "活動地址 2",
        "mission_city": "活動城市",
        "KOL_tier": "網紅等級",
        "mission_requirements1": "任務要求 1",
        "mission_requirements2": "任務要求  2",
        "application_deadline_date": "申請截止日期",
        "select_deadline_date": "選擇截止日期”",
        "application_deadline_time": "申請截止時間",
        "select_deadline_time": "選擇截止時間",
        "mission_status": "任務狀態",
        "mission_earn": "任務獎金",
        "browse_missions": "瀏覽任務",
        "create_mission": "創建任務",
        "missions_placeholder": "按任務ID或關鍵字搜索",

        "A_Z_of_Product": "產品",
        "product_placeholder": "按產品名稱搜索",
        "create_product": "創建產品",

        "role": "角色",
        "roles_colon": "角色:",
        "user_name": "使用戶名稱",
        "role_name": "角色名稱",
        "role_alert_1": "你確定要刪除",
        "remove_role": "刪除角色",
        "add_role": "添加角色",
        "yes_confirm": "是，確認",
        "user_role_list": "用戶角色列表",
        "assign_user_to_role": "將用戶分配到角色",

        "Scan_QR_Code": "掃描二維碼",
        "search_mission": "搜索任務",
        "search_mission_placeholder": "按任務名稱、ID或參考代碼搜索",
        "mission_id_colon": "任務ID:",
        "event_date_colon": "活動日期:",
        "status": "狀態",
        "status_colon": "狀態:",
        "hype_id_colon": "Hype ID:",
        "job_id_colon": "通告ID:",
        "arrival_time_colon": "抵達時間:",

        "search_social_media": "搜索社交媒體",
        "social_media_placeholder": "按姓名或ID搜索",
        "Add_New_Social_Media": "添加新的社交媒體",
        "search_social_id_colon": "社交媒體 ID：",
        "name_colon": "名稱：",
        "social_media_name": "社交媒體名稱",
        "updated_by_colon": "更新者：",
        "modified_at_colon": "修改於：",

        "vetting_jobs": "審批通告",
        "search_all_applications": "搜索所有申請提案",
        "vetting_jobs_placeholder": "按任務ID或關鍵字搜索",
        "brand_name_colon": "品牌名稱：",
        "event_name_colon": "事件名稱：",
        "event_deadline_colon": "活動截止日期：",
        "job_status": "通告狀態",
        "mission_name_id_colon": "任務名稱 (ID)：",
        "hype_name_id_colon": "Hype名稱 (ID)：",
        "ig_name_colon": "IG 名稱：",
        "no_of_followers_colon": "追蹤人數：",
        "no_of_posts_colon": "帖子數量：",

        "add_user": "添加用戶",
        "mobile_code": "手機代碼",
        "mobile_number": "手機號碼",
        "password": "密碼",
        "users": "用戶",
        "user_detail": "用戶資訊",
        "followers_colon": "追蹤者",
        "posts_colon": "帖子：",
        "Get_IG": "更新Instagram",
        "search_users": "搜索用戶",
        "search_users_placeholder": "按名稱或ID搜索",
        "add_new_user": "添加新用戶",
        "referral_code": "推薦代碼",
        "wallet_balance": "錢包餘額",
        "area_code": "區號",
        "save_changes": "保存",

        //新增
        "brand_colon": "品牌:",
        "brands_colon": "品牌:",
        "refresh_info": "更新資料",
        "referral_code_colon": "推薦代碼：",
        "wallet_balance_colon": "錢包餘額：",
        "tier_colon": "等級:",
        "language": "Language",
        "default_language": "默認語言",
        "user_tier": "用戶等級",
        "contact": "聯繫",
        "transaction_des":"交易內容",
        "no_transaction_record": "(無交易記錄)",
        "tier": "等級",
        "applying_missions_colon":"申請任務",
        "applying_missions_notice_1":"1. 只要你在Hype的Instagram賬戶有超過2,000追踪者，你便可開始申請任務。",
        "applying_missions_notice_2":"2. 你在Hype的等級是根據Instagram的追踪者數量確定的，每次登入Hype時，該等級會自動更新。",
        "applying_missions_notice_3":"3. 有些任務只對特定等級開放，相關細節會在任務要求中註明。",
        "tier_tooltip_message":"系統將在每天淩晨4點根據追蹤人數更新用戶等級。",

        "login_log":"登錄日誌",
        "log_details":"日誌資訊",
        "registration_time":"註冊時間",
        "last_login_time":"最後登錄時間",
        "successful_referral":"成功推薦",
        "transaction_details":"交易詳情",
        "Application_Status":"申請狀態",
        "export":"導出",
        "new_user":"新用戶",
        "all_missions":"全部任務",
        "tiers":"等級",
        "ins_follwers":"Instagram追蹤人數",
        "your_email":"你的電郵",
        "your_name":"你的名稱",
        "new_password":"新密碼",
        "confirm_password":"確認密碼",
        "bank_details":"銀行資料",
        "full_name_en":"英文全名",
        "bank_name":"銀行名稱",
        "bank_account_number":"銀行號碼",
        "phone_number":"電話號碼",
        "leger_balance":"帳面結餘",
        "available_balance":"可提取結餘",
        "balance_remarks_1":"當你的結餘達到300港元時，你可以提出提款申請。提款請求僅限於全額提取，不支援部分提款。",
        "balance_remarks_2":"請填寫收款人銀行資料後儲存。",
        "withdraw_amount": "提款金額",
        "currency": "$",
        "save_submit":"Save & Submit",
        "balance_remarks_3":"提款請求已成功提交！",
        "balance_remarks_4":"您的銀行資料已儲存並可編輯在「個人資料」內。",
        "close":"Close",
        "transaction_logs":"Transaction Logs",
        "search_all_transaction":"Search all Transactions",
        "transaction_placeholder":"Search Transactions by keywords",
        "transaction_type1":"Transaction Type",
        "transaction_type":"Transaction Type:",
        "reference_number":"Reference number:",
        "transaction_amount":"Transaction Amount:",
        "remarks":"Remarks:",
        "transaction_status":"Transaction Status",
        "mession_reward":"Mission Reward",
        "group":"Group",
        "followers":"追蹤人數",
        "description":"描述",
        "from":"最少",
        "to":"最多",
        "desc_remarks":"描述 (產品名稱及價值)",

        "to_must_from":"同一組內的To值不能小於From值。",
        "from_must_to":"追蹤者From值必須大於前一組的追蹤者To值。",
        "from_or_to":"From和To值都是必填的。",
        "validate_change_approve":"Mission Earn currency and amount cannot be blank",
        "wallet_history_remark1":"你輸入的銀行資料不正確或銀行無法處理，請於「個人資料」修改或與你的銀行聯絡後，再申請提取到正確的銀行戶口。",
        "withdrawal_reject_desc":"提取要求: 已驳回",
        "browse_transaction":"Browse Transaction",
        "transaction_type_title":"Transaction Type",
        "bank_full_name_remark":"請輸入您信用卡或銀行扣帳卡上的全名",
        "fps":"轉數快",
        "bank":"銀行轉帳",
        "bank_1_payment":"付款方式",
        "withdraw_for":"提取於",
        
    }
};
