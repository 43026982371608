<template>
    <el-header :class="isCollapse?'top-open-menu':''">
        <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false" :router="true">
            <el-button @click="toggleMenu" plain class="left-burger nohover">
                <i class="fa fa-navicon color-000000"></i>
            </el-button>

            <div class="flex-grow" />

            <div class="hype-lang-box">
                <el-submenu index="0">
                    <template #title>
                        <div class="hype-lang-info">{{$i18n.locale}}</div>
                    </template>
                    <select-language :theme-type="2"></select-language>
                </el-submenu>
            </div>

            <el-submenu index="1">
                <template #title>
                    <div class="profile-holder">
                        <el-image :src="$global.fileUrl+userInfo.ig_logid" class="border-radius-100">
                            <div slot="error">
                                <i class="fa fa-user-circle-o font-26 color-000000"></i>
                            </div>
                        </el-image>
                    </div>
                    <span class="color-000000">{{displayName}}</span>
                </template>
                <el-menu-item v-if="loginType && hasPermission('influencer_profile')" index="1-1" route="/profile">{{$t('common.profile')}}</el-menu-item>
                <el-menu-item v-if="!loginType && hasPermission('admin_user_list')" index="2-1" :route="`/admin/users-detail?id=${userInfo.id}`">{{$t('common.profile')}}</el-menu-item>
<!--                <el-menu-item v-if="!loginType && !hasPermission('admin_user_list')" index="2-1" :route="`/admin/profile`">{{$t('common.profile')}}</el-menu-item>-->
<!--                <el-menu-item v-if="!loginType" index="2-2" :route="`/admin/users-reset-pasword`">{{$t('common.change_password')}}</el-menu-item>-->
                <el-menu-item v-if="!loginType" index="2-2" :route="`/admin/change-pasword`">{{$t('common.change_password')}}</el-menu-item>
                <el-menu-item @click="handLogout">{{$t('common.logout')}}</el-menu-item>
            </el-submenu>
        </el-menu>
    </el-header>
</template>

<script>
    import {mapGetters} from "vuex";
    import {getCookies} from "@/utils/auth";
    import SelectLanguage from "@/components/SelectLanguage";

    export default {
        name: "TopHeader",
        components: {SelectLanguage},
        computed: {
            ...mapGetters(['sidebar', 'userInfo']),
            isCollapse() {
                return !this.sidebar.opened;
            },
        },
        data(){
            return {
                loginType:null,
                displayName:null
            }
        },
        created() {
            let that = this
            that.loginType = getCookies('LoginType')?.loginType != 'admin'
            that.docWidth();
            that.getDisplayName();
        },
        methods:{
            handLogout(){
                let that = this
                that.$store.dispatch('user/logout').then(res=>{
                    if (res.status){
                        let logoutUrl = getCookies('LoginType')?.loginType == 'admin' ? '/admin/login' : '/login'
                        that.$router.push(logoutUrl)
                    }
                })
            },
            toggleMenu(){
                let that = this
                that.$store.dispatch('app/toggleSideBar');
            },
            docWidth(){
                let that = this;
                if (document.body.clientWidth <= 768 && !that.isCollapse){
                    that.$store.dispatch('app/toggleSideBar')
                }
                // console.log(that.isCollapse)
            },
            getDisplayName(){
                let that = this;
                let tempName = '';
                let tempUserInfo = that.userInfo;
                if (tempUserInfo.display_name){
                    tempName = tempUserInfo.display_name
                }
                else if (tempUserInfo.family_name || tempUserInfo.first_name){
                    tempName = tempUserInfo.family_name + ' ' + tempUserInfo.first_name
                }
                else{
                    // tempName = tempUserInfo.mobile_number
                    // tempName = 'New User'
                    tempName = that.$t('page.new_user');
                }
                that.displayName = tempName
            },
        },
        mounted() {
            let that = this;
            window.addEventListener('resize', () => {
                that.docWidth();
            })
        },
        watch:{
            $route(to,form){
                let that = this;
                that.getDisplayName();
            },
            '$i18n.locale'(){
                this.getDisplayName()
            }
        }
    }
</script>

<style scoped>
    .el-menu-demo{ display: flex; position: fixed; width: calc(100% - 200px); background-color: #ffffff; z-index: 99;}
    .top-open-menu .el-menu-demo{ width: 100% !important}
    .el-menu--horizontal>.el-submenu{ margin-left: auto;}
    .left-burger {padding: 10px 20px;font-size: 20px;background: none;border: none!important;}
    .profile-holder {width: 30px;height: 30px;border-radius: 50%;background: var(--gray-bg);color: var(--hype-blue);margin-right: 10px;display: inline-block;text-align: center;line-height: 30px;font-weight: bold;}
    .el-header {position: relative;padding: 0px;margin: 0px;width: 100%;}
    .el-header .el-menu--horizontal {border: none;}
    .el-menu--horizontal .el-menu .el-menu-item {padding: 0px 20px; color: #000000;}
    .el-menu .el-menu-item:hover{ font-weight: bold;}
    .el-menu--horizontal > .el-menu-item.is-active,
    .el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {border: none;color: unset !important;}

    ::v-deep .el-submenu__title{ padding-left: 10px;}

    @media (max-width: 768px) {
        .el-menu-demo{ width: 100%; z-index: 88;}
        .left-burger{ font-size: 26px;}
    }
    @media (max-width: 560px) {
        ::v-deep .el-submenu__title{ padding: 0 10px;}
        .left-burger {padding: 10px;}
    }
</style>
