
const authRoutes = [
    {
        path: '/login',
        component: () => import('@/views/Auth/Login'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/login/password',
        component: () => import('@/views/Auth/LoginPassword'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/login/otp',
        component: () => import('@/views/Auth/LoginAndRegisterOtp'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/register',
        component: () => import('@/views/Auth/Register'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/register/otp',
        component: () => import('@/views/Auth/LoginAndRegisterOtp'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/forgot-password',
        component: () => import('@/views/Auth/ForgotPassword'),
        hidden: true,
        meta:{auth:false}
    },
    {
        path: '/forgot-password/otp',
        component: () => import('@/views/Auth/LoginAndRegisterOtp'),
        hidden: true,
        meta:{auth:false}
    },
]


export default authRoutes;
