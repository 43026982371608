/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const socialMediaRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/social-media',
    name:'Social Media List',
    meta: { title: 'social_media', icon: 'peoples', permissions: ['admin_social_media_list']},
    children: [
        {
            path: 'social-media',
            component: () => import('@/views_admin/SocialMedia/Index'),
            name: 'SocialMediaList',
            meta: { title: 'social_media', icon: '/assets/images/svgs/icons/profile.svg', permissions: ['admin_social_media_list']},
        },
        {
            path: 'social-media-create',
            component: () => import('@/views_admin/SocialMedia/Create'),
            name: 'SocialMediaCreate',
            hidden: true,
            meta: { title: 'social_media_create', permissions: ['admin_social_media_create']},
        },
    ],
};


export default socialMediaRoutes;
