export default {
    role: {
        permission_name:"许可权名称",
        select_all:"全选",

        /*influencer*/
        influencer_mission:'任务',
        influencer_mission_apply:'申请 [操作]',
        influencer_job:"我的通告",
        influencer_profile:"个人资料",
        influencer_invite_friends:"邀请朋友",
        user_submit_instagram_profile:"更新Instagram用户名称 [操作]",
        user_instagram_type_personal_handling:"Instagram个人用户处理",

        /*admin*/
        admin_dashboard:"数据报表",
        admin_analysis:"分析",
        admin_social_media:"社交媒体",
        admin_social_media_list:"社交媒体",
        admin_social_media_create:"社交媒体创建",
        admin_social_media_update:"社交媒体更新 [操作]",
        admin_mission:"任务",
        admin_mission_list:"任务",
        admin_mission_create:"任务创建",
        admin_mission_update:"任务更新",
        admin_job:"审批通告",
        admin_job_list:"审批通告",
        admin_job_update_status:"管理员决策 [操作]",
        admin_brand:"品牌",
        admin_brand_list:"品牌",
        admin_brand_create:"品牌创建",
        admin_brand_update:"品牌更新",
        admin_brand_dropdown_menu:"品牌下拉选单",
        admin_user:"用户",
        admin_user_list:"用户",
        admin_user_create:"用户创建 [操作]",
        admin_user_update:"用户更新",
        user_scrape_instagram_profile:"Instagram用户资料抓取 [操作]",
        admin_role:"角色",
        admin_role_list:"角色",
        admin_scan_qr:"扫描二维码",

        assign_user_role:"分配用户角色",
        remove_role:"移除角色",
        add_role:"添加角色",
        admin_user_transaction_details:"交易详情",
        admin_user_banlance_withdraw:"馀额提取",
        admin_user_export:'导出',
        admin_transaction:"Transaction",
        admin_transaction_status:"Transaction Status",
    }
};
