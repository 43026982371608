export default {
    status: {
        china:{
            FlagImage: '/assets/images/svgs/flags/cn.svg',
            CountryName: 'China',
            MobileCode: '+86',
            RequiredMobileNumberLength: [11]
        },
        mobileCodeList: [
            {
                FlagImage: '/assets/images/svgs/flags/hk.svg',
                CountryName: 'Hong Kong',
                MobileCode: '+852',
                RequiredMobileNumberLength: [8],
                sort:'A',
            },
            {
                FlagImage: '/assets/images/svgs/flags/au.svg',
                CountryName: 'Australia',
                MobileCode: '+61',
                RequiredMobileNumberLength: [10],
                sort:'A',
            },
            {
                FlagImage: '/assets/images/svgs/flags/it.svg',
                CountryName: 'Italy',
                MobileCode: '+39',
                RequiredMobileNumberLength: [9, 10],
                sort:'I',
            },
            // {
            //     FlagImage: '/assets/images/svgs/flags/ph.svg',
            //     CountryName: 'Philippines',
            //     MobileCode: '+63',
            //     RequiredMobileNumberLength: [10]
            // },
            {
                FlagImage: '/assets/images/svgs/flags/gb.svg',
                CountryName: 'United Kingdom',
                MobileCode: '+44',
                RequiredMobileNumberLength: [11],
                sort:'U',
            },
            {
                FlagImage: '/assets/images/svgs/flags/vn.svg',
                CountryName: 'Vietnam',
                MobileCode: '+84',
                RequiredMobileNumberLength: [12],
                sort:'V',
            },
            {
                FlagImage: '/assets/images/svgs/flags/South_Korea.svg',
                CountryName: 'South Korea',
                MobileCode: '+82',
                RequiredMobileNumberLength: [10,11],
                sort:'S',
            },
            {
                FlagImage: '/assets/images/svgs/flags/United_States.svg',
                CountryName: 'United States',
                MobileCode: '+1',
                RequiredMobileNumberLength: [10],
                sort:'U',
            },
            {
                FlagImage: '/assets/images/svgs/flags/Taiwan.svg',
                CountryName: 'Taiwan',
                MobileCode: '+886',
                RequiredMobileNumberLength: [8,9,10],
                sort:'T',
            },
            {
                FlagImage: '/assets/images/svgs/flags/Thailand.svg',
                CountryName: 'Thailand',
                MobileCode: '+66',
                RequiredMobileNumberLength: [9,10],
                sort:'T',
            },
            {
                FlagImage: '/assets/images/svgs/flags/cn.svg',
                CountryName: 'China',
                MobileCode: '+86',
                RequiredMobileNumberLength: [11],
                sort:'C',
            },
            {
                FlagImage: '/assets/images/svgs/flags/Malaysia.svg',
                CountryName: 'Malaysia',
                MobileCode: '+60',
                RequiredMobileNumberLength: [9,10],
                sort:'M',
            },
        ],
        countryList: [
            {
                FlagImage: '/assets/images/svgs/flags/au.svg',
                CountryName: 'Australia',
                CountryCode: 'AU'
            },
            {
                FlagImage: '/assets/images/svgs/flags/hk.svg',
                CountryName: 'Hong Kong',
                CountryCode: 'HK'
            },
            {
                FlagImage: '/assets/images/svgs/flags/it.svg',
                CountryName: 'Italy',
                CountryCode: 'IT'
            },
            {
                FlagImage: '/assets/images/svgs/flags/ph.svg',
                CountryName: 'Philippines',
                CountryCode: 'PH'
            },
            {
                FlagImage: '/assets/images/svgs/flags/gb.svg',
                CountryName: 'United Kingdom',
                CountryCode: 'GB'
            },
            {
                FlagImage: '/assets/images/svgs/flags/vn.svg',
                CountryName: 'Vietnam',
                CountryCode: 'VN'
            }
        ],
        genderList: [
            {Label: 'Male', Value: 'Male'},
            {Label: 'Female', Value: 'Female'},
            // {Label: 'Prefer not to say', Value: 'Prefer not to say'},
            {Label: 'Other', Value: 'Other'}
        ],
        social_media_status:[
            {label:'Active',value:0},
            {label:'Deactive',value:1},
        ],
        mission_status:[
            {label:'Draft',value:'Draft'},
            {label:'New',value:'New'},
            {label:'Open',value:'Open'},
            {label:'Closed',value:'Closed'},
            {label:'Cancelled',value:'Cancelled'},
        ],
        mission_status_new:[
            {label:'Cancelled',value:'m-s-0'},
            {label:'Draft',value:'m-s-1'},
            {label:'Reviewing by Hype',value:'m-s-2'},
            {label:'Published',value:'m-s-3'},
            {label:'Completed',value:'m-s-4'},
            {label:'Expired',value:'m-s-5'},
        ],

        mission_status_create:[
            {label:'Draft',value:'m-s-1'},
            {label:'Reviewing by Hype',value:'m-s-2'},
            {label:'Published',value:'m-s-3'},
        ],
        mission_status_edit:[
            {label:'Draft',value:'m-s-1'},
            {label:'Published',value:'m-s-3'},
            {label:'Cancelled',value:'m-s-0'},
        ],
        mission_earn_currency:[
            {label:'HKD',value:'HKD'},
        ],
        mission_type:[
            {label:'Event',value:'m-t-1'},
            {label:'Live-streaming',value:'m-t-2'},
            {label:'Post',value:'m-t-3'},
            {label:'Video',value:'m-t-4'},
        ],

        mission_status_list:{
            'm-s-0':'Cancelled',
            'm-s-1':'Draft',
            'm-s-2':'Reviewing by Hype',
            'm-s-3':'Published',
            'm-s-4':'Completed',
            'm-s-5':'Expired',
        },

        mission_type_list:{
            'm-t-1':'Event',
            'm-t-2':'Live-streaming',
            'm-t-3':'Post',
            'm-t-4':'Video',
        },

        wt_type:{
            'wt-t-1':'Signup Bonus',
            'wt-t-2':'Referral Reward',
            'wt-t-3':'Mission Payout',
            'wt-t-4':'Withdrawal',
            'wt-t-100':'Withdrawal',
        },

        wt_status:{
            'wt-s-1':'Settled',
            'wt-s-2':'Processing',
            'wt-s-3':'Rejected',
            'wt-s-4':'Settled',

        },

        transaction_status:[
            {label:'Settled',value:'wt-s-1'},
            {label:'Processing',value:'wt-s-2'},
            {label:'Rejected',value:'wt-s-3'},
            // {label:'Settled',value:'wt-s-4'},
        ],


        mission_status_tab:[
            {label:'Active Missions',value:'m-s-3'},
            {label:'Expired Missions',value:'m-s-5'},
            {label:'All Missions',value:''},
        ],
        influencer_job_status:[
            {label:'Under Review',value:'Under Review'},
            {label:'Approved',value:'Approved'},
            {label:'Rejected',value:'Rejected'},
            {label:'Attended',value:'Attended'},
            {label:'Expired',value:'Closed'},
        ],
        influencer_job_status_tab:[
            {label:'All Jobs',value:''},
            {label:'Under Review',value:'j-s-1'},
            {label:'Approved',value:'j-s-2'},
            {label:'Rejected',value:'j-s-3'},
            {label:'Completed',value:'j-s-5'},
        ],
        influencer_job_status_new:[
            {label:'Under Review',value:'j-s-1'},
            {label:'Approved',value:'j-s-2'},
            {label:'Rejected',value:'j-s-3'},
            {label:'Withdraw',value:'j-s-4'},
            {label:'Completed',value:'j-s-5'},
            {label:'Rewarded',value:'j-s-6'},
        ],
        influencer_job_status_new_list:[
            {label:'All Jobs',value:''},
            {label:'Under Review',value:'j-s-1',type:'jl_job_status'},
            {label:'Approved',value:'j-s-2',type:'jl_job_status'},
            {label:'Rejected',value:'j-s-3',type:'jl_job_status'},
            {label:'Withdraw',value:'j-s-4',type:'jl_job_status'},
            {label:'Completed',value:'j-s-5',type:'jl_job_status'},
            {label:'Rewarded',value:'j-s-6',type:'jl_job_status'},
            // {label:'Expired',value:'j-s-5',type:'mission_status'},
            // {label:'Cancelled',value:'j-s-6',type:'mission_status'},
        ],
        transaction_status_new_list:[
            {label:'All',value:'',type:'wt_type_new'},
            {label:'Processing Withdrawal',value:'wt-ts-1',type:'wt_type_new'},
            {label:'Settled Withdrawal',value:'wt-ts-2',type:'wt_type_new'},
            {label:'Rejected Withdrawal',value:'wt-ts-3',type:'wt_type_new'},
            {label:'Rewarded',value:'wt-ts-4',type:'wt_type_new'},
        ],
        admin_vet_status:[
            {label:'Under Review',value:'Under Review'},
            {label:'Approved',value:'Approved'},
            {label:'Rejected',value:'Rejected'},
        ],
        admin_vet_status_new:[
            {label:'Under Review',value:'Under Review'},
            {label:'Approved',value:'Approved'},
            {label:'Rejected',value:'Rejected'},
            {label:'Withdraw',value:'Withdraw'},
        ],
        adminDecisionList: [
            {"Label": "Under Review", "Value": "UNDER_REVIEW"},
            {"Label": "Approved", "Value": "APPROVED"},
            {"Label": "Rejected", "Value": "REJECTED"},
            {"Label": "Withdraw", "Value": "WITHDRAW"}
        ],
        user_status:[
            {label:'Active',value:1},
            {label:'Deactive',value:0},
        ],

        //新增
        kol_tier:[
            {label:'Bronze',value:'1',ig_flollowers:'2,000-9,999'},
            {label:'Silver',value:'2',ig_flollowers:'10,000-19,999'},
            {label:'Gold',value:'3',ig_flollowers:'20,000-29,999'},
            {label:'Diamond',value:'4',ig_flollowers:'30,000+'},
        ],
        admin_kol_tier:[
            {label:'Unqualified',value:'0'},
            {label:'Bronze',value:'1'},
            {label:'Silver',value:'2'},
            {label:'Gold',value:'3'},
            {label:'Diamond',value:'4'},
        ],
        user_status_list:[
            {label:'Active',value:'1'},
            {label:'De-Active',value:'0'},
            {label:'All Users',value:''},
        ],
        langList:[
            {label:'English',value:'en'},
            {label:'Traditional Chinese',value:'tc'},
            {label:'Others',value:'others'},
        ],
        langListNoOther:[
            {label:'English',value:'en'},
            {label:'繁體中文',value:'tc'},
        ],

        operation_code_list:{
            'u-bol-0': 'Register',
            'u-bol-0-1': 'Register By TikTok',
            'u-bol-0-2': 'Registration By Instagram',
            'u-bol-1': 'Add User',
            'u-bol-2': 'Profile Update',
            'u-bol-4': 'Login',
            'u-bol-5': 'User Enablement',
            'u-bol-6': 'User Disablement',
        },

        'u-bol-0': 'Registration',
        'u-bol-0-1': 'Registration By TikTok',
        'u-bol-0-2': 'Registration By Instagram',
        'u-bol-1': 'Add User',
        'u-bol-2': 'Profile Update',
        'u-bol-4': 'Login',
        'u-bol-5': 'User Enablement',
        'u-bol-6': 'User Disablement',

        mission_admin_searh_status_list:{
            'active':'Active',
            'expired':'Expired',
            'submitted':'Reviewing by Hype',
            'draft':'Draft',
            'all':'All',
        },
    }
};
