export default {
    page: {
        "my_jobs": "我的通告",
        "current_jobs": "现有通告",
        "mission_id": "任务ID",
        "website": "网站",
        "earn": "赚取:",
        "open_to": "开放给",
        "hk_m": "HK$",
        "withdraw": "余额提取",
        "withdraw_1": "撤回任务",
        "rejected": "拒绝",
        "attended": "已参与",
        "completed": "已完成",
        "missions": "任务",
        "product": "产品",
        "learn_more": "浏览更多",
        "apply_now": "申请",
        "applied": "已申请",
        "expired": "已逾期",
        "cancelled": "已取消",
        "sure": "是的，我确定",
        "no": "不是",
        "yes": "是",
        "alert_message_1": "你肯定要",
        "alert_message_2": "通告?",
        "event_confirm_text_1": "你已成功申请任务",
        "event_confirm_text_2": "你的参考编号：",
        "got_it": "知道了",
        "apply_until_colon": "申请截止日期：",
        "ig_unsuccessful_text_1": "与你的Instagram帐户连接失败。",
        "ig_unsuccessful_text_2": "绑定失败",
        "ig_unsuccessful_text_3": "是个人帐户，",
        "ig_unsuccessful_text_4": "请查看",
        "instagram": "instagram",
        "instagram_colon": "Instagram:",
        "ig_unsuccessful_text_5": "使用商业或创作者帐户。",
        "ig_unsuccessful_text_6": "联络我们",
        "ig_unsuccessful_text_7": "@ 我们的Whatsapp商业帐号.",
        "your_referral_id": "你的推荐ID：",
        "referred_by": "推荐者ID：",
        "your_details": "你的资料",
        "edit_details": "编辑资料",
        "first_name": "名字",
        "last_name": "姓氏",
        "display_name": "暱称",
        "gender": "性别",
        "birthday": "生日日期",
        "email": "电邮地址",
        "mobile": "手机号码",
        "social_media_account": "社交媒体帐户",
        "instagram_unf": "Instagram",
        "instagram_unf_text_1": "请输入你的 Instagram 用户名称以完成绑定过程。",
        "connect_your_instagram_account": "连接你的Instagram帐户",
        "username": "用户名称@",
        "last_updated_colon": "最后更新:",
        "invalid_username": "用户名称无效",

        "recommend_to_connect": "推荐连接你的社交媒体帐户",
        "please_input_your_ig_username": "请输入你的 Instagram 用户名称以完成绑定过程。",
        "connect": "连接",
        "i_will_connect_later": "我稍后会在我的个人资料中连接",

        "invite_friends": "邀请朋友",
        "invite_people_and_earn": "邀请朋友获得奖赏",
        "invite_people_earn_text_1": "推荐奖金将添加到您的钱包中，如果你的推荐人：",
        "invite_people_earn_text_2": "- 在Instagram上拥有超过2,000名追踪者",
        "invite_people_earn_text_3": "- 完成一项任务",
        "invite_people_earn_text_4": "馀额将于14个工作天内在你的钱包中更新。",
        "your_referral_QR_code": "你的推荐二维码",
        "user_id_colon": "用户ID:",
        "your_referral_link": "你的推荐连结",
        "your_contact": "你的联繫方式",
        "type_colon": "类型:",
        "username_colon": "用户名称:",

        "wallet": "钱包",
        "your_balance": "你的馀额",
        "hkd": "港幣",
        "transaction_History": "交易纪录",
        "contact_us": "联络我们",

        "brand": "品牌",
        "brands": "品牌",
        "influencers": "网红",
        "A_Z_of_Brands_Profiles": "品牌资料",
        "brand_placeholder": "输入你的品牌所在区域 (例如: 越南, 香港, 亚太区)",
        "Create_Brand_Profile": "创建品牌资料",
        "brand_id_colon": "品牌ID:",
        "brand_code_colon": "品牌代号:",
        "active": "活跃中",
        "brand_logo": "品牌标志",
        "brand_code": "品牌代码",
        "brand_name": "品牌名称",
        "brand_mission": "品牌使命",
        "brand_full_name": "品牌全名",
        "brand_banner": "品牌横幅",
        "brand_website": "品牌网站",
        "Brand_IG_1st_Origin": "品牌Instagram 1",
        "Brand_IG_1st_Origin_URL": "品牌Instagram 1 链结",
        "Brand_IG_2nd_Origin": "品牌Instagram 2",
        "Brand_IG_2nd_Origin_URL": "品牌Instagram 2 链结",
        "Brand_FB_1st_Origin": "品牌Facebook 1",
        "Brand_FB_1st_Origin_URL": "品牌Facebook 1 链结",
        "Brand_FB_2nd_Origin": "品牌Facebook 2 链结",
        "Brand_FB_2nd_Origin_URL": "品牌Facebook 2 链结",
        "Brand_Short_description1": "品牌简介",
        "Brand_Long_description1": "品牌详细说明 1",
        "Brand_Long_description2": "品牌详细说明 2",
        "Brand_Long_description3": "品牌详细说明 3",
        "Brand_Long_description4": "品牌详细说明 4",
        "Brand_User_define_Field_1": "Brand User define Field 1",
        "Brand_User_define_Field_2": "Brand User define Field 2",
        "Brand_User_define_Field_3": "Brand User define Field 3",
        "last_update_by_colon": "最后更新者：",
        "update_at_colon": "更新于:",
        "created_at_colon": "创建时间：",
        "created_by_colon": "创建者：",
        "update_by_colon": "更新者：",

        "assign_influencers": "分配网红",
        "mission_type": "任务类型",
        "event_name": "任务名称",
        "no_of_participants": "参与人数",
        "mission_short_description": "任务简介",
        "mission_long_description1": "任务详细说明 1",
        "mission_long_description2": "任务详细说明 2",
        "mission_long_description1_placeholder": "吸引的活动内容，例如：礼品包、讲座、无限畅饮等",
        "mission_start_date": "任务开始日期",
        "select_start_date": "选择开始日期",
        "mission_start_time": "任务开始时间",
        "select_start_time": "选择开始时间",
        "mission_end_date": "任务结束日期",
        "select_end_date": "选择结束日期",
        "mission_end_time": "任务结束时间",
        "select_end_time": "选择结束时间",
        "event_address1": "活动地址 1",
        "event_address2": "活动地址 2",
        "mission_city": "活动城市",
        "KOL_tier": "网红等级",
        "mission_requirements1": "任务要求 1",
        "mission_requirements2": "任务要求  2",
        "application_deadline_date": "申请截止日期",
        "select_deadline_date": "选择截止日期”",
        "application_deadline_time": "申请截止时间",
        "select_deadline_time": "选择截止时间",
        "mission_status": "任务状态",
        "mission_earn": "任务奖金",
        "browse_missions": "浏览任务",
        "create_mission": "创建任务",
        "missions_placeholder": "按任务ID或关键字搜索",

        "A_Z_of_Product": "产品",
        "product_placeholder": "按产品名称搜索",
        "create_product": "创建产品",

        "role": "角色",
        "roles_colon": "角色:",
        "user_name": "使用户名称",
        "role_name": "角色名称",
        "role_alert_1": "你确定要删除",
        "remove_role": "删除角色",
        "add_role": "添加角色",
        "yes_confirm": "是，确认",
        "user_role_list": "用户角色列表",
        "assign_user_to_role": "将用户分配到角色",

        "Scan_QR_Code": "扫描二维码",
        "search_mission": "搜索任务",
        "search_mission_placeholder": "按任务名称、ID或参考代码搜索",
        "mission_id_colon": "任务ID:",
        "event_date_colon": "活动日期:",
        "status": "状态",
        "status_colon": "状态:",
        "hype_id_colon": "Hype ID:",
        "job_id_colon": "通告ID:",
        "arrival_time_colon": "抵达时间:",

        "search_social_media": "搜索社交媒体",
        "social_media_placeholder": "按姓名或ID搜索",
        "Add_New_Social_Media": "添加新的社交媒体",
        "search_social_id_colon": "社交媒体 ID：",
        "name_colon": "名称：",
        "social_media_name": "社交媒体名称",
        "updated_by_colon": "更新者：",
        "modified_at_colon": "修改于：",

        "vetting_jobs": "审批通告",
        "search_all_applications": "搜索所有申请提案",
        "vetting_jobs_placeholder": "按任务ID或关键字搜索",
        "brand_name_colon": "品牌名称：",
        "event_name_colon": "事件名称：",
        "event_deadline_colon": "活动截止日期：",
        "job_status": "通告状态",
        "mission_name_id_colon": "任务名称 (ID)：",
        "hype_name_id_colon": "Hype名称 (ID)：",
        "ig_name_colon": "IG名称：",
        "no_of_followers_colon": "追踪人数：",
        "no_of_posts_colon": "帖子数量：",

        "add_user": "添加用户",
        "mobile_code": "手机代码",
        "mobile_number": "手机号码",
        "password": "密码",
        "users": "用户",
        "user_detail": "用户资讯",
        "followers_colon": "追踪者",
        "posts_colon": "帖子：",
        "Get_IG": "更新Instagram",
        "search_users": "搜索用户",
        "search_users_placeholder": "按名称或ID搜索",
        "add_new_user": "添加新用户",
        "referral_code": "推荐代码",
        "wallet_balance": "钱包馀额",
        "area_code": "区号",
        "save_changes": "保存",

        //新增
        "brand_colon": "品牌:",
        "brands_colon": "品牌:",
        "refresh_info": "更新资料",
        "referral_code_colon": "推荐代码：",
        "wallet_balance_colon": "钱包馀额：",
        "tier_colon": "等级:",
        "language": "Language",
        "default_language": "默认语言",
        "user_tier": "用户等级",
        "contact": "联繫",
        "transaction_des":"交易内容",
        "no_transaction_record": "(无交易记录)",
        "tier": "等级",
        "applying_missions_colon":"申请任务",
        "applying_missions_notice_1":"1. 当您在Hype绑定的Instagram账户有超过2,000关注者，您便可开始申请任务。",
        "applying_missions_notice_2":"2. 您在Hype的级别是基于您Instagram的关注者数量，每次登录Hype会自动更新。",
        "applying_missions_notice_3":"3. 有的任务仅针对特定级别的用户，详情会在任务要求中注明。",
        "tier_tooltip_message":"系统将在每天凌晨4点根据追踪人数更新用户等级。",

        "login_log":"登录日志",
        "log_details":"日志资讯",
        "registration_time":"注册时间",
        "last_login_time":"最后登录时间",
        "successful_referral":"成功推荐",
        "transaction_details":"交易详情",
        "Application_Status":"申请状态",
        "export":"导出",
        "new_user":"新用户",
        "all_missions":"全部任务",
        "tiers":"等级",
        "ins_follwers":"Instagram追踪人数",
        "your_email":"你的电邮",
        "your_name":"你的名称",
        "new_password":"新密码",
        "confirm_password":"确认密码",
        "bank_details":"銀行資料",
        "full_name_en":"英文全名",
        "bank_name":"銀行名稱",
        "bank_account_number":"銀行號碼",
        "phone_number":"電話號碼",
        "leger_balance":"帳面結餘",
        "available_balance":"可提取结余",
        "balance_remarks_1":"當你的結餘達到300港元時，你可以提出提款申請。提款請求僅限於全額提取，不支援部分提款。",
        "balance_remarks_2":"請填寫收款人銀行資料後儲存。",
        "withdraw_amount": "提款金額",
        "currency": "$",
        "save_submit":"Save & Submit",
        "balance_remarks_3":"提款請求已成功提交！",
        "balance_remarks_4":"您的銀行資料已儲存並可編輯在「個人資料」內。",
        "close":"Close",
        "transaction_logs":"Transaction Logs",
        "search_all_transaction":"Search all Transactions",
        "transaction_placeholder":"Search Transactions by keywords",
        "transaction_type1":"Transaction Type",
        "transaction_type":"Transaction Type:",
        "reference_number":"Reference number:",
        "transaction_amount":"Transaction Amount:",
        "remarks":"Remarks:",
        "transaction_status":"Transaction Status",
        "mession_reward":"Mission Reward",
        "group":"Group",
        "followers":"追蹤人數",
        "description":"描述",
        "from":"最少",
        "to":"最多",
        "desc_remarks":"描述 (产品名称及价值)",

        "to_must_from":"同一组内的To值不能小于From值。",
        "from_must_to":"追踪者From值必须大于前一组的追踪者To值。",
        "from_or_to":"From和To值都是必填的。",
        "validate_change_approve":"Mission Earn currency and amount cannot be blank",
        "wallet_history_remark1":"你輸入的銀行資料不正確或銀行無法處理，請於「個人資料」修改或與你的銀行聯絡後，再申請提取到正確的銀行戶口。",
        "withdrawal_reject_desc":"提取要求: 已驳回",
        "browse_transaction":"Browse Transaction",
        "transaction_type_title":"Transaction Type",
        "bank_full_name_remark":"請輸入您信用卡或銀行扣帳卡上的全名",
        "fps":"轉數快",
        "bank":"銀行轉帳",
        "bank_1_payment":"付款方式",
        "withdraw_for":"提取於",
        
    }
};
