import request from '@/utils/request';
import {isLogged} from "@/utils/auth";

export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data: data,
        isLogged: true,
        loading:false
    });
}
export function register(data) {
    return request({
        url: '/register',
        method: 'post',
        data: data,
        isLogged: true,
        loading:false
    });
}
export function forgotPassword(data) {
    return request({
        url: '/user/forgot-password',
        method: 'post',
        data: data,
        isLogged: true,
        loading:true
    });
}
export function changePassword(data) {
    data.loading = true
    return request({
        url: '/user/change-password',
        method: 'post',
        data: data,
        isLogged: true,
        loading:true
    });
}

export function getInfo(token) {
    return request({
        url: '/user_info',
        method: 'get',
        isLogged: true,
    });
}

export function logout() {
    return request({
        url: '/logout',
        method: 'get',
    });
}

export function platformAuth(url,data) {
    if (url){
        return request({
            url: url,
            method: 'post',
            data: data,
            isLogged: true,
        });
    }
}

export function csrf() {
    return request({
        url: '/sanctum/csrf-cookie',
        method: 'get',
        isLogged: true,
    });
}
