export default {
    home: {
        "what_we_do": "服务",
        "how_it_works": "微网红行销",
        "why_hype": "解决方案?",
        "our_brands": "品牌合作",
        "our_influencers": "热门网红及创作者",
        "contact_us": "联络我们",

        "banner_text1": "发挥你的影响力<br>一展所长",
        "banner_text2": "联繫品牌与亚洲优质微网红的平台",
        "for_brands": "对于<br>品牌",
        "for_brands_text": "提升你的品牌形象: 与亚洲人气网红及创作者合作，协助你有效推广业务",
        "for_brands_text_1": "严选网红配对",
        "for_brands_text_2": "网红接洽管理",
        "for_brands_text_3": "品牌活动发佈",
        "for_brands_text_4": "内容优化及管理",
        "for_brands_text_5": "成效分析报告",
        "for_influencers": "对于<br>网红",
        "for_influencers_text": "提升你的曝光率：自由选择与国际及本地品牌合作，透过内容创作获得奖赏。",
        "for_influencers_text_1": "宣传活动配对",
        "for_influencers_text_2": "专业品牌洽谈",
        "for_influencers_text_3": "内容创作资源",
        "for_influencers_text_4": "网红交流机会",
        "for_influencers_text_5": "成效分析报告",

        "for_influencer_step_list": [
            {icon:'./assets/images/icon/user.png', title:'注册成为Hype微网红', content:['拥有Instagram创作者或商业帐户', '多于3,000 Instagram追踪人数']},
            {icon:'./assets/images/icon/search.png', title:'浏览任务', content:['一键申请任务', '让品牌查看你的Instagram内容']},
            {icon:'./assets/images/icon/electricity.png', title:'完成任务', content:['按照清晰指引完成任务', 'Hype团队确认任务达成']},
            {icon:'./assets/images/icon/money.png', title:'赚取奖赏', content:['于Hype钱包中收款', '提款到您的银行帐户']},
        ],

        "why_hype_list_1": [
            { title:'寻找合适的网红', content:'搜索优质的亚洲微网红和创作者，汇聚他们的影响力为你的品牌塑形。' },
            { title:'有效分析回报率', content:'使用智能的数据驱动面板来评估你的品牌活动成效。' },
            { title:'以人为本的营销角度', content:'消费者更相信他们关注的微网红所分享的真实感受。' },
            { title:'自选合适服务', content:'自由选择并自订最合适的网红宣传服务计画。' },
            { title:'为品牌增添本地色彩', content:'运用引人入胜的内容策略，吸引不同地区的目标受众关注你的品牌' },
        ],
        "why_hype_list_2": [
            { title:'值得信赖的合作伙伴', content:'我们仅与值得信赖的品牌合作，重视并保护你的个人品牌。' },
            { title:'创造无限潜能', content:'与本地及国际品牌合作，凸显鲜明形象并提升曝光率。' },
            { title:'掌握主动权', content:'无须等待被发掘，主动选择与有兴趣的品牌合作。' },
            { title:'发挥创意和热情', content:'分享日常所爱，随时获得奖赏.' },
            { title:'网红圈内交流', content:'发展您的专业网路，为新的合作机会打开大门。' },
            { title:'经营专属网店', content:'简单步骤即可开立网店，轻鬆经营赚取额外收益', subTitle:'(即将推出)' },
        ],

        "influencer_list": [
            {img:'./assets/images/home/home_7_1.jpg', viewImg:'./assets/images/home/home_7_1_2.jpg', name:'Yu Cheng', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 运动, 生活品味'},
            {img:'./assets/images/home/home_7_2.jpg', viewImg:'./assets/images/home/home_7_2_2.jpg', name:'Amara', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 美容, 生活品味'},
            {img:'./assets/images/home/home_7_3.jpg', viewImg:'./assets/images/home/home_7_3_2.jpg', name:'Tanya', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 运动, 生活品味'},
            {img:'./assets/images/home/home_7_4.jpg', viewImg:'./assets/images/home/home_7_4_2.jpg', name:'Ting Yin', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'美容, 个人护理, 饮食'},
            {img:'./assets/images/home/home_7_5.jpg', viewImg:'./assets/images/home/home_7_5_2.jpg', name:'Kannas', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 美容, 生活品味'},
            {img:'./assets/images/home/home_7_6.jpg', viewImg:'./assets/images/home/home_7_6_2.jpg', name:'Birdie', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'饮食, 生活品味'},
            {img:'./assets/images/home/home_7_7.jpg', viewImg:'./assets/images/home/home_7_7_2.jpg', name:'Hei Hei', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'电竞, 数码产品, 个人护理'},
            {img:'./assets/images/home/home_7_8.jpg', viewImg:'./assets/images/home/home_7_8_2.jpg', name:'Juliana', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'美容, 运动, 个人品味'},
        ],


        "why_influencer_marketing": "网红行销解决方案",
        "why_influencer_marketing_text_1": "提升品牌知名度",
        "why_influencer_marketing_text_2": "透过网红的日常分享，向更广泛的受众分享品牌资讯。",
        "why_influencer_marketing_text_3": "推动产品销售",
        "why_influencer_marketing_text_4": "与网红的受众建立真实联繫，从而影响购买决策。",
        "why_influencer_marketing_text_5": "建立使用者原创内容(UGC)",
        "why_influencer_marketing_text_6": "在不同的行销管道中运用网红的使用者生成内容，增强品牌的内容策略。",

        "how_it_works_text_1": "我们协助品牌提升在亚洲市场的知名度，将他们与才华横溢的网红联繫起来。",
        "how_it_works_text_2": "寻找网红在Instagram为你创作内容、参加推广活动或主持直播。只需在Hype上发佈一个任务, 网红便能即时申请与你的品牌合作。",
        "how_it_works_text_3": "透过简单的平台页面，品牌可以一键筛选及选择最合适的微网红。",
        "how_it_works_text_4": "与我们一同创建有影响力的网红活动。",
        "how_it_works_text_5": "选择你希望合作的品牌。",
        "how_it_works_text_6": '在Hype平台探查看品牌列出的任务，例如内容创作、参加品牌宣传活动或直播。',
        "how_it_works_text_7": "内容创作 (Instagram posts, stories, reels)",
        "how_it_works_text_8": "推广活动",
        "how_it_works_text_9": "直播",
        "how_it_works_text_10": "Hype store 专属网店(即将推出)",

        "our_brands_text_1": "深受国际品牌信赖",
        "our_brands_text_2": "合作品牌分享",
        "our_brands_text_3": "芬迪曼 Fentmans",
        "our_brands_text_4": "英国百年汤力水品牌“芬迪曼 Fentimans”，採用天然植物配方，传承英式古法酿造技术，持续打造丰富口感的高颜值饮品。",

        "ready_to_amplify_your_brand": "准备好扩展你的品牌了吗？",
        "talk_with_our_business_team": "与我们的业务团队联络",
        "ready_to_join_our_platform": "准备好发挥你的影响力了吗",
        "request_more_information": "索取更多资讯",
        "request_proposal": "要求提案",
        "your_email_address": "你的电邮地址",

        "contact_us_text_1": "Hype是",
        "contact_us_text_2": "eCargo",
        "contact_us_text_3": "旗下的公司.",
        "contact_us_text_4": "香港办公室",
        "contact_us_text_5": "香港新界葵涌<br/>三号货柜码头<br/>13楼13103N室",
        "contact_us_text_6": "社交媒体",
    }
};
