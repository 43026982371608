import i18n from '@/lang';
let i18nStatus = i18n.messages[i18n.locale].status
const global_ = {
    langList: i18nStatus.langList,
    langListNoOther: i18nStatus.langListNoOther,
    fileUrl:process.env.VUE_APP_FILE_URL,
    businessWhatsPhone:'+852 5596 9135',
    businessWhatsapp:'https://wa.me/85255969135',
    OTP_LENGTH:4,
    gutter:20,
    getMobileCodeList(){
        let tempStatus = JSON.parse(JSON.stringify(i18n.messages[i18n.locale].status))
        let tempArr = JSON.parse(JSON.stringify(tempStatus.mobileCodeList))
        // if (process.env.VUE_APP_BUILD_TYPE == 'DEV'){
        //     tempArr.push(i18nStatus.china)
        // }
        tempArr.sort((a, b) => a.sort.localeCompare(b.sort));

        // console.log(i18n.messages)
        // console.log(i18n.locale)
        // console.log(tempArr);
        return tempArr;
    },
    mobileCodeList: i18nStatus.mobileCodeList,
    countryList: i18nStatus.countryList,
    genderList: i18nStatus.genderList,
    social_media_status: i18nStatus.social_media_status,
    mission_status: i18nStatus.mission_status,
    mission_status_new: i18nStatus.mission_status_new,
    mission_status_create: i18nStatus.mission_status_create,
    mission_status_edit: i18nStatus.mission_status_edit,
    mission_earn_currency: i18nStatus.mission_earn_currency,
    mission_type: i18nStatus.mission_type,
    mission_status_list: i18nStatus.mission_status_list,
    mission_type_list: i18nStatus.mission_type_list,
    mission_status_tab: i18nStatus.mission_status_tab,
    influencer_job_status: i18nStatus.influencer_job_status,
    influencer_job_status_tab: i18nStatus.influencer_job_status_tab,
    influencer_job_status_new: i18nStatus.influencer_job_status_new,
    influencer_job_status_new_list: i18nStatus.influencer_job_status_new_list,
    transaction_status_new_list: i18nStatus.transaction_status_new_list,
    admin_vet_status: i18nStatus.admin_vet_status,
    admin_vet_status_new: i18nStatus.admin_vet_status_new,
    adminDecisionList: i18nStatus.adminDecisionList,
    user_status: i18nStatus.user_status,

    //新增
    kol_tier: i18nStatus.kol_tier,
    admin_kol_tier: i18nStatus.admin_kol_tier,
    user_status_list: i18nStatus.user_status_list,
    wt_type:i18nStatus.wt_type,
    wt_status:i18nStatus.wt_status,
    pickerOptionsOld18:{
        disabledDate(time) {
            let year18 = global_.getTimes(18,true)
            return time.getTime() > new Date(year18).getTime()
        },
    },
    getTimes(reduce,day) {
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        if (mm<10){
            mm = '0'+mm
        }
        let dd = new Date().getDate();
        if (dd<10){
            dd = '0'+dd
        }
        let hh = new Date().getHours();
        let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes();
        let ss = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds();
        let tempTime = ''
        if (reduce){
            if (day){
                tempTime = yy-reduce + "-" + mm + "-" + dd
                // tempTime = mm + "-" + dd + "-" + yy-reduce
            }else{
                tempTime = yy-reduce + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss
                // tempTime = mm + "-" + dd + "-" + yy-reduce + " " + hh + ":" + mf + ":" + ss
            }
        }else{
            tempTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss
            // tempTime = mm + "-" + dd + "-" + yy-reduce + " " + hh + ":" + mf + ":" + ss
        }
        return tempTime
    },
    // 设置选择今天以及今天之后的日期
    pickerOptions: {
        disabledDate(time) {
            return time.getTime() > Date.now();
        }
    },
    // 设置选择今天之后的日期（不能选择当天时间）
    pickerNoTodayOptions: {
        disabledDate(time) {
            return time.getTime() < Date.now();
        }
    },
}
export default{
    global_
}
