export default {
    menu: {
        "home":"主頁",
        "mission_list":"任務",
        "job_list":"我的通告",
        "profile_list":"個人資料",
        "invite_friends":"邀請朋友",
        "wallet_list":"我的錢包",
        "product":"產品",

        //admin
        "dashboard":"數據頁面",
        "analysis":"分析報告",
        "role":"角色",
        "social_media":"社交媒體",
        "brand":"品牌",
        "brand_create":"創建品牌資料",
        "brand_update":"更新品牌資料",
        "mission":"任務",
        "mission_create":"創建任務",
        "mission_update":"更新任務",
        "mission_detail":"任務詳情",
        "users":"用戶",
        "users_log_detail":"使用者操作日誌",
        "vetting_job":"審批通告",
        "scan_list":"掃描二維碼",
        "users_transaction_details":"交易詳情",
        "transaction_list":"交易",
    }
};
