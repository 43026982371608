
const authRoutesAdmin = [
    {
        path: '/admin/login',
        component: () => import('@/views_admin/Auth/Login'),
        hidden: true,
        meta: { auth:false},
    },
    {
        path: '/admin/register',
        component: () => import('@/views_admin/Auth/Register'),
        hidden: true,
        meta: { auth:false},
    },
    {
        path: '/admin/forgot-password',
        component: () => import('@/views_admin/Auth/ForgotPassword'),
        hidden: true,
        meta: { auth:false},
    },
    {
        path: '/admin/reset-password',
        component: () => import('@/views_admin/Auth/ResetPassword'),
        hidden: true,
        meta: { auth:false},
    },
]


export default authRoutesAdmin;
