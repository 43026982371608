/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const dashboardRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/dashboard',
    name:'Dashboard List',
    meta: { title: 'dashboard', icon: 'peoples', permissions: ['admin_dashboard']},
    children: [
        {
            path: 'dashboard',
            component: () => import('@/views_admin/Dashboard/Index'),
            name: 'DashboardList',
            meta: { title: 'dashboard', icon: '/assets/images/svgs/icons/dashboard.svg', permissions: ['admin_dashboard']},
        },
    ],
};


export default dashboardRoutes;
