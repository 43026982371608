export default {
    message: {
        "password_inconsistency":"密碼不一致",
        "required":"必填",
        "required_selection":"必填選項",
        "min_max_alert":"較小的數值不大於較大的數值",
        "please_enter_correct_value":"請輸入正確的數值",
        "no_data":"未找到數據",
        "least_one_name":"至少輸入一個名稱",
        "parameter_unusual":"參數異常，請重新輸入",
        "submitted_successfully":"提交成功",
        "submitted_failed":"提交失敗",
        "operation_successfully":"操作成功",
        "please_uplaod_file":"請上傳檔案",
        "success":"成功",
        "failed" :"失敗",
        "update_successful":"更新成功",
        "update_failed" :"更新失敗",
        "import_success":"導入成功",
        "import_failed" :"導入失敗",
        "upload_file_success":"上載成功",
        "upload_file_failed":"上載失敗",
        "maximum_size_of_5MB":"檔案不能大於5MB",
        "EPI20001":"已存在",
        "loading_failed":"載入失敗",
        "select_price":"請選擇報價",
        "cannot_repeat":"無法重複",
        "enquiry_not_exit":"查詢不存在",
        "preorder_is_exit":"要求已被提交。請不要重複提交",
        "E10006":"Error: 驗證碼無效",
        "E10007":"Error: 使用者名或密碼無效",
        "E10008":"Error: 電子郵件不存在",
        "login_error":"Error: 使用者名或密碼無效",
        "old_password_incorrect":"舊密碼不正確。",
        "no_approval_permission":"無許可權",
        "please_select_file_to_upload": "請選擇要上傳的檔案",
        "upload_template_format": "上傳範本只有XLS, XLSX, CSV 格式!",
        "upload_template_size_5mb": "上傳範本大小不超過5MB!",
        "exchange_rate_can_not_be_empty": "匯率不能為空",
        "form_submit_failure":"表單未更改，提交失敗",
        "one_must_at_least_check":"至少必須檢查",
        "check_on_the_menu":"至少檢查選單上的一個選項",
        "customer_selects_at_least_one_item":"客戶至少選擇一個選項",
        "correct_address_msg":"請輸入有效的電郵地址",
        "email_address_msg": "請輸入你的電郵地址",
        "tel_already_exist": "電話已存在",
        "email_already_exist": "電郵地址已存在",
        "email_cannot_be_repeated": "電郵地址不能重複",
        "tel_cannot_be_repeated": "電話不能重複",
        "name_cannot_be_repeated": "名字不能重複",
        "tel_and_email_are_required": "需要電話和電郵地址",
        "stakeholder_payee_exsits":"收款人已存在。",
        "template_is_error":"範本錯誤",
        "osp_company_is_exit":"包裝公司已經存在",
        "customer_is_exit":"客戶存在",
        "not_need_to_submit":"數據未被修改，不需要提交",
        "insufficient_balance":"餘額不足",
        "max_350_char":"不能超過 350 個字元",
        "max_300_char":"不能超過 300 個字元)",
        "max_255_char":"不能超過 255 個字元",
        "max_80_char":"不能超過 80 個字元",
        "max_50_char":"不能超過 50 個字元",
        "max_20_char":"不能超過 20 個字元",
        "required_while_save_without_details":"沒有詳細資訊保存時需要，",
        "password_minimum_6_characters":"密碼至少為 6 個字元。",
        "pls_8_char":"請使用更強的密碼。最少8個字元",
        "pwd_too_low":"密碼強度太低。",
        "mission_earn_is_required": "任務獎金不能為空",

        "message_1": "請在繼續之前接受我們的條款和條件以及隱私政策。",
        "message_2": "手機號碼不存在。請使用其他手機號碼登錄或進入註冊頁面。",
        "message_3_1": "請使用有效的手機號碼，",
        "message_3_2": "數字是必需的。",
        "message_4": "成功更改密碼。",
        "message_5": "你多次輸入無效代碼。請稍後再試。",
        "message_6": "已成功重新發送驗證碼。",
        "message_7": "請選擇要上傳的圖片。",
        "message_8": "圖像類型必須是.GIF，jpeg，jpg，png，bmp",
        "message_9": "已成功複製到剪貼板！",
        "message_10": "不能與使用者ID相同",
        "message_11": "忘記密碼成功。",
        "message_12": "電子郵件不存在。",
        "message_13": "登錄成功。",
        "message_14": "重置密碼成功。",
        "message_15": "更新成功。",
        "message_16": "需要社交媒體名稱",
        "message_17": "創建成功。",
        "message_18":"你需要達到任務要求等級後才可申請參與。詳情請查閱「我的資料 > 等級」。",

        "please_retry_later":"請稍後再試",
        "username_not_found":"找不到該用戶名稱",
    }
};
