export default {
    auth: {
        "or": "or",
        "text_1": "What is your mobile number?",
        "text_2": "Login with your mobile number",
        "text_3": "Haven't got an account yet?",
        "text_4": "Register now",
        "text_5": "Continue with Instagram",
        "text_6": "Be more than an influencer today",
        "text_7": "How many instagram followers do you have?",
        "text_8": "Register with your mobile number",
        "text_9": "Already have an account?",
        "text_10": "Forgot password",
        "text_11": "Enter your mobile number associated with your Hype account",
        "text_12_1": "Please enter the ",
        "text_12_2": "-digit code",
        "text_13": "You have entered invalid code multiple times. Please try again later.",
        "text_14": "The code you entered is incorrect.",
        "text_15_1": "Re-send code in",
        "text_15_2": "minutes.",
        "text_16": "Re-send code.",
        "text_17": "Please enter new password",
        "text_18": "Please re-enter new password",
        "text_19": "Log In with password",
        "text_20": "Log In with OTP",
        "text_21": "Cannot remember your password?",
        "text_22": "Please enter password",
        "text_23": "Please enter number",
        "text_24": "We sent a code to this mobile number:",
        "text_25": "Forgot Password",
        "text_25_2": "Check Your Email",
        "text_26": "Enter your email to receive password reset instructions.",
        "text_27": "Has your email changed?",
        "text_28": "We've sent a password reset link to your email address.<br>The link will expire in 30 minutes.",
        "text_29": "Admin Login",
        "text_30": "Login with your email",
        "text_31": "Trouble logging in?",
        "text_32": "Reset password",
        "text_33": "Your email",
        "text_34": "Your name",
        "text_35": "New Password",
        "text_36": "Confirm Password",
        "text_37": "Generate OTP",
        "text_38": "",
        "text_39": "",
        "text_40": "",
        "text_41": "Back to Login",
        "text_42": "For further assistance,",
        "text_43": "Password changed successfully.",
    }
};
