/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const missionRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/mission',
    name:'Admin Mission List',
    meta: { title: 'mission', icon: 'peoples', permissions: ['admin_mission_list']},
    children: [
        {
            path: 'mission',
            component: () => import('@/views_admin/Mission/Index'),
            name: 'AdminMissionList',
            meta: { title: 'mission', icon: '/assets/images/svgs/icons/mission.svg', permissions: ['admin_mission_list']},
        },
        {
            path: 'mission-create',
            component: () => import('@/views_admin/Mission/Create'),
            name: 'AdminMissionCreate',
            hidden: true,
            meta: { title: 'mission_create', permissions: ['admin_mission_create']},
        },
        {
            path: 'mission-update',
            component: () => import('@/views_admin/Mission/Update'),
            name: 'AdminMissionUpdate',
            hidden: true,
            meta: { title: 'mission_update', permissions: ['admin_mission_update']},
        },
        {
            path: 'mission-detail',
            component: () => import('@/views_admin/Mission/Detail'),
            name: 'AdminMissionDetail',
            hidden: true,
            meta: { title: 'mission_detail', permissions: ['admin_mission_list']},
        },
    ],
};


export default missionRoutes;
