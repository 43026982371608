export default {
    page: {
        "my_jobs": "My Jobs",
        "current_jobs": "Current Jobs",
        "mission_id": "Mission ID",
        "website": "Website",
        "earn": "Earn:",
        "open_to": "Open to",
        "hk_m": "HK$",
        "withdraw": "Withdraw",
        "withdraw_1": "Withdraw",
        "rejected": "Rejected",
        "attended": "Attended",
        "completed": "Completed",
        "missions": "Missions",
        "product": "Product",
        "learn_more": "Learn More",
        "apply_now": "Apply now",
        "applied": "Applied",
        "expired": "Expired",
        "cancelled": "Cancelled",
        "sure": "Yes, I am sure",
        "no": "No",
        "yes": "Yes",
        "alert_message_1": "Are you sure to",
        "alert_message_2": "job?",
        "event_confirm_text_1": "You have successfully applied for Mission",
        "event_confirm_text_2": "Your reference number:",
        "got_it": "Got it",
        "apply_until_colon": "Apply until:",
        "ig_unsuccessful_text_1": "Connection with your instagram account is unsuccessful.",
        "ig_unsuccessful_text_2": "Binding failed",
        "ig_unsuccessful_text_3": "is a Personal account,",
        "ig_unsuccessful_text_4": "please see",
        "instagram": "instagram",
        "instagram_colon": "Instagram:",
        "ig_unsuccessful_text_5": "to swith to Business or Creator Account.",
        "ig_unsuccessful_text_6": "Contact us",
        "ig_unsuccessful_text_7": "@ our business Whatsapp.",
        "your_referral_id": "Your referral ID:",
        "referred_by": "Referred by:",
        "your_details": "Your details",
        "edit_details": "Edit details",
        "first_name": "First name",
        "last_name": "Last name",
        "display_name": "Display name",
        "gender": "Gender",
        "birthday": "Birthday",
        "email": "Email",
        "mobile": "Mobile",
        "social_media_account": "Social media account",
        "instagram_unf": "Instagram",
        "instagram_unf_text_1": "Please enter your Instagram username below to complete the binding process.",
        "connect_your_instagram_account": "Connect your Instagram account",
        "username": "Username@",
        "last_updated_colon": "Last Updated:",
        "invalid_username": "Invalid username",

        "recommend_to_connect": "Recommend to connect with your social media",
        "please_input_your_ig_username": "Please input your IG username",
        "connect": "Connect",
        "i_will_connect_later": "I will connect in My Profile later",

        "invite_friends": "Invite Friends",
        "invite_people_and_earn": "Invite people and earn",
        "invite_people_earn_text_1": "Referral bonus will be added to your wallet if your referee:",
        "invite_people_earn_text_2": "- Has over 2,000 followers on Instagram",
        "invite_people_earn_text_3": "- Completes one mission",
        "invite_people_earn_text_4": "Please allow 14 business days for balance to be reflected in your wallet.",
        "your_referral_QR_code": "Your referral QR code",
        "user_id_colon": "User ID:",
        "your_referral_link": "Your referral link",
        "your_contact": "Your contact",
        "type_colon": "Type:",
        "username_colon": "Username:",

        "wallet": "Wallet",
        "your_balance": "Total Balance",
        "hkd": "HKD",
        "transaction_History": "Transaction History",
        "contact_us": "Contact us",

        "brand": "Brand",
        "brands": "Brands",
        "influencers": "Influencers",
        "A_Z_of_Brands_Profiles": "A-Z of Brands Profiles",
        "brand_placeholder": "Enter the area your brand is in. (E.g.: Vietnam, Hong Kong, APAC)",
        "Create_Brand_Profile": "Create Brand Profile",
        "brand_id_colon": "Brand ID:",
        "brand_code_colon": "Brand Code:",
        "active": "Active",
        "brand_logo": "Brand logo",
        "brand_code": "Brand code",
        "brand_name": "Brand name",
        "brand_mission": "Brand mission",
        "brand_full_name": "Brand Full name",
        "brand_banner": "Brand banner",
        "brand_website": "Brand Website",
        "Brand_IG_1st_Origin": "Brand IG 1st Origin",
        "Brand_IG_1st_Origin_URL": "Brand IG 1st Origin URL",
        "Brand_IG_2nd_Origin": "Brand IG 2nd Origin",
        "Brand_IG_2nd_Origin_URL": "Brand IG 2nd Origin URL",
        "Brand_FB_1st_Origin": "Brand FB 1st Origin",
        "Brand_FB_1st_Origin_URL": "Brand FB 1st Origin URL",
        "Brand_FB_2nd_Origin": "Brand FB 2nd Origin",
        "Brand_FB_2nd_Origin_URL": "Brand FB 2nd Origin URL",
        "Brand_Short_description1": "Brand Short description1",
        "Brand_Long_description1": "Brand Long description1",
        "Brand_Long_description2": "Brand Long description2",
        "Brand_Long_description3": "Brand Long description3",
        "Brand_Long_description4": "Brand Long description4",
        "Brand_User_define_Field_1": "Brand User define Field 1",
        "Brand_User_define_Field_2": "Brand User define Field 2",
        "Brand_User_define_Field_3": "Brand User define Field 3",
        "last_update_by_colon": "Last Update by:",
        "update_at_colon": "Update at:",
        "created_at_colon": "Created at:",
        "created_by_colon": "Created by:",
        "update_by_colon": "Update by:",

        "assign_influencers": "Assign influencers",
        "mission_type": "Mission Type",
        "event_name": "Event name",
        "no_of_participants": "No. of participants",
        "mission_short_description": "Mission Short description",
        "mission_long_description1": "Mission Long description 1",
        "mission_long_description2": "Mission Long description 2",
        "mission_long_description1_placeholder": "What is expected at the event? E.g.: Gift bags, panel sesstion, special activities, free flow drinks, etc.",
        "mission_start_date": "Mission Start date",
        "select_start_date": "Select Start date",
        "mission_start_time": "Mission Start time",
        "select_start_time": "Select Start time",
        "mission_end_date": "Mission End date",
        "select_end_date": "Select End date",
        "mission_end_time": "Mission End time",
        "select_end_time": "Select End time",
        "event_address1": "Event Address1",
        "event_address2": "Event Address2",
        "mission_city": "Mission City",
        "KOL_tier": "KOL Tier",
        "mission_requirements1": "Mission Requirements 1",
        "mission_requirements2": "Mission Requirements 2",
        "application_deadline_date": "Application Deadline date",
        "select_deadline_date": "Select Deadline date",
        "application_deadline_time": "Application Deadline time",
        "select_deadline_time": "Select Deadline time",
        "mission_status": "Mission Status",
        "mission_earn": "Mission Fee",
        "browse_missions": "Browse missions",
        "create_mission": "Create Mission",
        "missions_placeholder": "Search missions by mission ID or keywords",

        "A_Z_of_Product": "A-Z of Product",
        "product_placeholder": "Search by product name",
        "create_product": "Create Product",

        "role": "Role",
        "roles_colon": "Roles:",
        "user_name": "User Name",
        "role_name": "Role Name",
        "role_alert_1": "Are you sure you want to delete",
        "remove_role": "Remove Role",
        "add_role": "Add Role",
        "yes_confirm": "Yes, Confirm",
        "user_role_list": "User role list",
        "assign_user_to_role": "Assign user to role",

        "Scan_QR_Code": "Scan QR Code",
        "search_mission": "Search Mission",
        "search_mission_placeholder": "Search mission by name or ID or reference code",
        "mission_id_colon": "Mission ID:",
        "event_date_colon": "Event date:",
        "status": "Status",
        "status_colon": "Status:",
        "hype_id_colon": "Hype ID:",
        "job_id_colon": "Job ID:",
        "arrival_time_colon": "Arrival Time:",

        "search_social_media": "Search Social Media",
        "social_media_placeholder": "Search by name or ID",
        "Add_New_Social_Media": "Add New Social Media",
        "search_social_id_colon": "Social Media Id:",
        "name_colon": "Name:",
        "social_media_name": "Social media name",
        "updated_by_colon": "Updated By:",
        "modified_at_colon": "Modified at:",

        "vetting_jobs": "Vetting Jobs",
        "search_all_applications": "Search all applications",
        "vetting_jobs_placeholder": "Search missions by mission ID or keywords",
        "brand_name_colon": "Brand name:",
        "event_name_colon": "Event name:",
        "event_deadline_colon": "Event deadline:",
        "job_status": "Job Status",
        "mission_name_id_colon": "Mission Name (ID):",
        "hype_name_id_colon": "Hype Name (ID):",
        "ig_name_colon": "IG Name:",
        "no_of_followers_colon": "No. of followers:",
        "no_of_posts_colon": "No. of posts:",

        "add_user": "Add user",
        "mobile_code": "Mobile Code",
        "mobile_number": "Mobile Number",
        "password": "Password",
        "users": "Users",
        "user_detail": "User Detail",
        "followers_colon": "Followers:",
        "posts_colon": "Posts:",
        "Get_IG": "Get IG",
        "search_users": "Search Users",
        "search_users_placeholder": "Search by name or ID",
        "add_new_user": "Add new user",
        "referral_code": "Referral Code",
        "wallet_balance": "Wallet Balance",
        "area_code": "Area Code",
        "save_changes": "Save changes",

        //新增
        "brand_colon": "Brand:",
        "brands_colon": "Brands:",
        "refresh_info": "Refresh Info",
        "referral_code_colon": "Referral Code:",
        "wallet_balance_colon": "Wallet Balance:",
        "tier_colon": "Tier:",
        "language": "Language",
        "default_language": "Default language",
        "user_tier": "User Tier",
        "contact": "Contact",
        "transaction_des":"Transaction Description",
        "no_transaction_record": "(No Transaction Record)",
        "tier": "Tier",
        "applying_missions_colon":"Applying Missions",
        "applying_missions_notice_1":"1. You may begin to apply missions with more than 2,000 followers on your Instagram account linked with Hype",
        "applying_missions_notice_2":"2. Your tier is based on your number of Instagram followers, it will be updated when you log into your Hype portal",
        "applying_missions_notice_3":"3. Some missions are open to specific tiers only and will be specified in the Mission's requirement",
        "tier_tooltip_message":"System will check and update user tier based on no of followers every day at 4am.",

        "login_log":"Login log",
        "log_details":"Log details",
        "registration_time":"Registration time",
        "last_login_time":"Last login time",
        "successful_referral":"Successful Referral",
        "transaction_details":"Transaction details",
        "Application_Status":"Mission Status",
        "export":"Export",
        "new_user":"New User",
        "all_missions":"All Missions",
        "tiers":"Tiers",
        "ins_follwers":"Instagram Followers",
        "your_email":"Your Email",
        "your_name":"Your Name",
        "new_password":"New Password",
        "confirm_password":"Confirm Password",
        "bank_details":"Bank Details",
        "full_name_en":"Full Name",
        "bank_name":"Bank Name",
        "bank_account_number":"Bank Account Number",
        "phone_number":"Phone Number",
        "leger_balance":"Ledger Balance",
        "available_balance":"Available Balance",
        "balance_remarks_1":"You can request to withdraw your balance once it reaches HKD 300. Please be informed that this withdrawal request is for the full available balance in your account. Partial withdrawals are not supported.",
        "balance_remarks_2":"Please complete your receiving bank details below and click “Save”.",
        "withdraw_amount": "Withdraw Amount",
        "currency": "$",
        "save_submit":"Save & Submit",
        "balance_remarks_3":"The withdraw request have been submitted successfully!",
        "balance_remarks_4":"Your bank details are saved and can be edited on “Profile”",
        "close":"Close",
        "transaction_logs":"Transaction Logs",
        "search_all_transaction":"Search all Transactions",
        "transaction_placeholder":"Search Transactions by keywords",
        "transaction_type1":"Transaction Type",
        "transaction_type":"Transaction Type:",
        "reference_number":"Reference number:",
        "transaction_amount":"Transaction Amount:",
        "remarks":"Remarks:",
        "transaction_status":"Transaction Status",
        "mession_reward":"Mission Reward",
        "group":"Group",
        "followers":"Followers",
        "description":"Description",
        "from":"From",
        "to":"To",
        "desc_remarks":"Description (product name and value)",

        "to_must_from":"The 'To' value cannot less than the 'From' value within the same group.",
        "from_must_to":"The 'Followers From' value must be greater than the 'Followers To' value of the previous group.",
        "from_or_to":"'From' and 'To' values are required.",
        "validate_change_approve":"Mission Earn currency and amount cannot be blank",
        "wallet_history_remark1":"There is an issue processing your withdrawal request. Please edit your bank details on “Profile” or check with your bank.",
        "withdrawal_reject_desc":"Withdrawal request: rejected",
        "browse_transaction":"Browse Transaction",
        "transaction_type_title":"Transaction Type",
        "bank_full_name_remark":"Please enter your name exactly as it appears on your debit or credit card for payment processing",
        "fps":"FPS",
        "bank":"Bank transfer",
        "bank_1_payment":"Payment Preference",
        "withdraw_for":"Withdraw for",
    }
};
