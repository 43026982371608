export default {
    common: {
        "sign_up": "注册",
        "login": "登入",
        "log_in": "登入",
        "register": "注册",
        "logout": "登出",
        "profile": "个人资料",
        "step": "步骤",
        "type": "类型",
        "value": "值",
        "cancel": "取消",
        "upload": "上传",
        "close": "关闭",
        "update": "更新",
        "submit": "提交",
        "continue": "继续",
        "add": "添加",
        "remove": "删除",
        "save": "储存",
        "edit": "编辑",
        "back": "返回",
        "status": "状态",
        "copy": "复制",
        "details": "详情",
        "assign": "分配",
        "password": "密码",
        "user_id": "用户帐号",
        "contact": "联络",
        "address": "地址",
        "address_1": "香港新界葵涌三号货柜码头13楼13103N室",
        "qr_reader": "二维码阅读器",
        "register_text_1": "点击注册，即表示您确认已阅读并接受我们公司的",
        "terms_conditions": "条款及细则",
        "terms_and_conditions": "条款及细则",
        "privacy_policy": "隐私条例",
        "please_select": "请选择",
        "select": "选择",
        "must_be_over_18_years_old": "必须年满18岁",
        "choose_picture": "选择图片",
        "instagram_tooltip_text_1": "为了申请任务并令推荐人获得奖金，您的Instagram帐户必须是创作者或商业帐户。",
        "instagram_tooltip_text_2": "如何切换到创作者帐户？请查看",
        "instagram_tooltip_text_3": "如何切换到商业帐户？请查看",
        "instagram_tooltip_text_4": "这裡",
        "wallet_balance_tooltip_1": "钱包馀额:",
        "wallet_balance_tooltip_2": "您的錢包餘額將在採取任何行動後的14個工作日內更新",
        "wallet_balance_tooltip_3": "當餘額達到港幣300元時，您可以要求將餘額提取至您的銀行帳戶。",
        "wallet_balance_tooltip_4": "钱包馀额",
        "wallet_balance_tooltip_5": "",
        "wallet_balance_tooltip_6": "",
        "change_password":"修改密码",
        "reset_password":"重置密码",
    }
};
