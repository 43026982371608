<template>
    <div>
        <template v-if="themeType==1">
            <el-submenu index="0-1" class="hype-item-lang">
                <template slot="title">
                    <div :class="themeFold?'hype-submenu-hover':'hype-submenu-title'">{{$t('page.language')}}</div>
                </template>
                <el-menu-item v-for="(item,index) in $t('status.langListNoOther')" :key="index" @click="changeLang(item.value)">
                    <span :class="$i18n.locale == item.value ? 'font-weight-bold' : ''">{{item.label}}</span>
                </el-menu-item>
            </el-submenu>
        </template>
        <template v-if="themeType==2">
            <el-menu-item v-for="(item,index) in $t('status.langListNoOther')" :key="index" @click="changeLang(item.value)">
                <span :class="$i18n.locale == item.value ? 'font-weight-bold' : ''">{{item.label}}</span>
            </el-menu-item>
        </template>
    </div>
</template>

<script>
    export default {
        name: "SelectLanguage",
        props:{
            themeFold:{
                type: Boolean,
                default: false
            },
            themeType:{
                type: Number,
                default: 1
            }
        },
        methods:{
            changeLang(lang){
                let that = this
                console.log(lang)
                that.$i18n.locale = lang;
                that.$store.dispatch('app/setLanguage', lang);
            },
        }
    }
</script>

<style scoped>
    .hype-submenu-title {
        padding: 0 10px!important; color: #000000!important;
    }
    .el-menu--horizontal .el-menu .el-menu-item {
        padding: 0px 20px; color: #000000;
    }

    .el-menu .el-menu-item:hover,
    .el-menu .el-submenu:hover .el-submenu__title,
    .hype-item-lang:hover .hype-submenu-hover,
    .hype-item-lang:hover .hype-submenu-title
    { font-weight: bold!important; color: #000000!important;}
</style>
