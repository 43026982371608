/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const walletRoutes = {
    path: '/wallet',
    component: Layout,
    redirect: '/wallet/list',
    name:'Wallet List',
    meta: { title: 'wallet_list', icon: 'peoples'/*, permissions: ['influencer_job']*/},
    children: [
        {
            path: '',
            component: () => import('@/views/Wallet/Index'),
            name: 'WalletList',
            meta: { title: 'wallet_list', icon: '/assets/images/svgs/icons/Ui icons=Wallet.svg',/* permissions: ['influencer_job']*/},
        },
    ],
};


export default walletRoutes;
