export default {
    home: {
        "what_we_do": "服務",
        "how_it_works": "微網紅行銷",
        "why_hype": "解決方案?",
        "our_brands": "品牌合作",
        "our_influencers": "熱門網紅及創作者",
        "contact_us": "聯絡我們",

        "banner_text1": "發揮你的影響力<br>一展所長",
        "banner_text2": "聯繫品牌與亞洲優質微網紅的平台",
        "for_brands": "對於<br>品牌",
        "for_brands_text": "提升你的品牌形象: 與亞洲人氣網紅及創作者合作，協助你有效推廣業務",
        "for_brands_text_1": "嚴選網紅配對",
        "for_brands_text_2": "網紅接洽管理",
        "for_brands_text_3": "品牌活動發佈",
        "for_brands_text_4": "內容優化及管理",
        "for_brands_text_5": "成效分析報告",
        "for_influencers": "對於<br>網紅",
        "for_influencers_text": "提升你的曝光率：自由選擇與國際及本地品牌合作，透過內容創作獲得獎賞。",
        "for_influencers_text_1": "宣傳活動配對",
        "for_influencers_text_2": "專業品牌洽談",
        "for_influencers_text_3": "內容創作資源",
        "for_influencers_text_4": "網紅交流機會",
        "for_influencers_text_5": "成效分析報告",

        "for_influencer_step_list": [
            {icon:'./assets/images/icon/user.png', title:'註冊成為Hype微網紅', content:['擁有Instagram創作者或商業帳戶', '多於3,000 Instagram追蹤人數']},
            {icon:'./assets/images/icon/search.png', title:'瀏覽任務', content:['一鍵申請任務', '讓品牌查看你的Instagram內容']},
            {icon:'./assets/images/icon/electricity.png', title:'完成任務', content:['按照清晰指引完成任務', 'Hype團隊確認任務達成']},
            {icon:'./assets/images/icon/money.png', title:'賺取獎賞', content:['於Hype錢包中收款', '提款到您的銀行帳戶']},
        ],

        "why_hype_list_1": [
            { title:'尋找合適的網紅', content:'搜索優質的亞洲微網紅和創作者，匯聚他們的影響力為你的品牌塑形。' },
            { title:'有效分析回報率', content:'使用智能的數據驅動面板來評估你的品牌活動成效。' },
            { title:'以人為本的營銷角度', content:'消費者更相信他們關注的微網紅所分享的真實感受。' },
            { title:'自選合適服務', content:'自由選擇並自訂最合適的網紅宣傳服務計畫。' },
            { title:'為品牌增添本地色彩', content:'運用引人入勝的內容策略，吸引不同地區的目標受眾關注你的品牌' },
        ],
        "why_hype_list_2": [
            { title:'值得信賴的合作夥伴', content:'我們僅與值得信賴的品牌合作，重視並保護你的個人品牌。' },
            { title:'創造無限潛能', content:'與本地及國際品牌合作，凸顯鮮明形象並提升曝光率。' },
            { title:'掌握主動權', content:'無須等待被發掘，主動選擇與有興趣的品牌合作。' },
            { title:'發揮創意和熱情', content:'分享日常所愛，隨時獲得獎賞.' },
            { title:'網紅圈內交流', content:'發展您的專業網路，為新的合作機會打開大門。' },
            { title:'經營專屬網店', content:'簡單步驟即可開立網店，輕鬆經營賺取額外收益', subTitle:'(即將推出)' },
        ],

        "influencer_list": [
            {img:'./assets/images/home/home_7_1.jpg', viewImg:'./assets/images/home/home_7_1_2.jpg', name:'Yu Cheng', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 運動, 生活品味'},
            {img:'./assets/images/home/home_7_2.jpg', viewImg:'./assets/images/home/home_7_2_2.jpg', name:'Amara', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 美容, 生活品味'},
            {img:'./assets/images/home/home_7_3.jpg', viewImg:'./assets/images/home/home_7_3_2.jpg', name:'Tanya', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 運動, 生活品味'},
            {img:'./assets/images/home/home_7_4.jpg', viewImg:'./assets/images/home/home_7_4_2.jpg', name:'Ting Yin', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'美容, 個人護理, 飲食'},
            {img:'./assets/images/home/home_7_5.jpg', viewImg:'./assets/images/home/home_7_5_2.jpg', name:'Kannas', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'健康, 美容, 生活品味'},
            {img:'./assets/images/home/home_7_6.jpg', viewImg:'./assets/images/home/home_7_6_2.jpg', name:'Birdie', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'飲食, 生活品味'},
            {img:'./assets/images/home/home_7_7.jpg', viewImg:'./assets/images/home/home_7_7_2.jpg', name:'Hei Hei', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'電競, 數碼產品, 個人護理'},
            {img:'./assets/images/home/home_7_8.jpg', viewImg:'./assets/images/home/home_7_8_2.jpg', name:'Juliana', city:'香港', city_icon:'./assets/images/svgs/flags/hk.svg', content1:'', content2:'美容, 運動, 個人品味'},
        ],


        "why_influencer_marketing": "網紅行銷解決方案",
        "why_influencer_marketing_text_1": "提升品牌知名度",
        "why_influencer_marketing_text_2": "透過網紅的日常分享，向更廣泛的受眾分享品牌資訊。",
        "why_influencer_marketing_text_3": "推動產品銷售",
        "why_influencer_marketing_text_4": "與網紅的受眾建立真實聯繫，從而影響購買決策。",
        "why_influencer_marketing_text_5": "建立使用者原創內容(UGC)",
        "why_influencer_marketing_text_6": "在不同的行銷管道中運用網紅的使用者生成內容，增強品牌的內容策略。",

        "how_it_works_text_1": "我們協助品牌提升在亞洲市場的知名度，將他們與才華橫溢的網紅聯繫起來。",
        "how_it_works_text_2": "尋找網紅在Instagram為你創作內容、參加推廣活動或主持直播。只需在Hype上發佈一個任務, 網紅便能即時申請與你的品牌合作。",
        "how_it_works_text_3": "透過簡單的平台頁面，品牌可以一鍵篩選及選擇最合適的微網紅。",
        "how_it_works_text_4": "與我們一同創建有影響力的網紅活動。",
        "how_it_works_text_5": "選擇你希望合作的品牌。",
        "how_it_works_text_6": '在Hype平台探查看品牌列出的任務，例如內容創作、參加品牌宣傳活動或直播。',
        "how_it_works_text_7": "內容創作 (Instagram posts, stories, reels)",
        "how_it_works_text_8": "推廣活動",
        "how_it_works_text_9": "直播",
        "how_it_works_text_10": "Hype store 專屬網店(即將推出)",

        "our_brands_text_1": "深受國際品牌信賴",
        "our_brands_text_2": "合作品牌分享",
        "our_brands_text_3": "芬迪曼 Fentmans",
        "our_brands_text_4": "英國百年湯力水品牌「芬迪曼 Fentimans」，採用天然植物配方，傳承英式古法釀造技術，持續打造豐富口感的高顏值飲品。",

        "ready_to_amplify_your_brand": "準備好擴展你的品牌了嗎？",
        "talk_with_our_business_team": "與我們的業務團隊聯絡",
        "ready_to_join_our_platform": "準備好發揮你的影響力了嗎",
        "request_more_information": "索取更多資訊",
        "request_proposal": "要求提案",
        "your_email_address": "你的電郵地址",

        "contact_us_text_1": "Hype是",
        "contact_us_text_2": "eCargo",
        "contact_us_text_3": "旗下的公司.",
        "contact_us_text_4": "香港辦公室",
        "contact_us_text_5": "香港新界葵涌<br/>三號貨櫃碼頭<br/>13樓13103N室",
        "contact_us_text_6": "社交媒體",
    }
};
