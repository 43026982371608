import router from './router';
import store from './store';
import { Message } from 'element-ui';
import {isLogged, setLogged, getCookies} from '@/utils/auth';
import getPageTitle from '@/utils/get-page-title';
import { checkPermission, userPermissionRoute, getPermission, getPermissionOneLink } from '@/utils/permission'; // Permission checking

// const whiteList = ['', '/login', '/register', '/redirect', '/qr', '/login/otp', '/register/otp']; // no redirect whitelist
// const authPath = ['/login', '/register', '/forgot-password', '/admin/reset-password']

router.beforeEach(async (to, from, next) => {
    // set page title
    document.title = getPageTitle(to.meta.title);

    // determine whether the user has logged in
    const isUserLogged = isLogged();
    // next();
    let loginType = getCookies('LoginType')?.loginType == 'admin' ? true : false
    if (to.path.indexOf('/api') != -1){
        next();
    }
    else{
        if (isUserLogged) {
            let userInfo = store.getters.userInfo
            if (!userInfo || !Object.keys(userInfo).length){
                await store.dispatch('user/getInfo');
                await store.dispatch('common/getCommon')
            }else {
                setLogged(1);
            }
            userPermissionRoute()

            let goUrl = loginType ? getPermissionOneLink() || '/' : '/mission/list'

            if ( to.path && ( (loginType && to.path.indexOf('admin')!=-1) || !loginType && to.path.indexOf('admin')==-1 ) || to.path == '/' || to.path == '/404'){
                if (to.path.indexOf('/login') === -1 && to.path.indexOf('/register') === -1 && to.path != '/forgot-password') {

                    let permissions = getPermission()
                    if (permissions.length){
                        if (to?.meta?.permissions){
                            if (!checkPermission(to.meta.permissions)){
                                next({ path: '/404' });
                            }else{
                                next();
                            }
                        }else {
                            next();
                        }
                    }else {
                        if (to?.meta?.permissions){
                            next({ path: '/404' });
                        }else {
                            // Jump to the page without permission according to the actual situation
                            next();
                        }
                    }
                }else{
                    next({ path: goUrl });
                }
            }
            else {
                // 绑定平台时候需要用到的重定向页面
                if (to.path == '/redirect'){
                    next();
                }
                else{
                    next({ path: goUrl });
                }
            }

        } else {
            let { auth } = to.meta
            if (auth === undefined || auth === true){
                let goUrl = loginType ? '/admin/login' : '/login'
                next(goUrl);
                // next(`/login?redirect=${to.fullPath}`);
            }else {
                next();
            }
        }
    }
});

router.afterEach(() => {
});
