export default {
    status: {
        china:{
            FlagImage: '/assets/images/svgs/flags/cn.svg',
            CountryName: '中國',
            MobileCode: '+86',
            RequiredMobileNumberLength: [11]
        },
        mobileCodeList: [
            {
                FlagImage: '/assets/images/svgs/flags/hk.svg',
                CountryName: '中國香港特別行政區',
                MobileCode: '+852',
                RequiredMobileNumberLength: [8],
                sort:'A',
            },
            {
                FlagImage: '/assets/images/svgs/flags/au.svg',
                CountryName: '澳大利亞',
                MobileCode: '+61',
                RequiredMobileNumberLength: [10],
                sort:'A',
            },
            {
                FlagImage: '/assets/images/svgs/flags/it.svg',
                CountryName: '意大利',
                MobileCode: '+39',
                RequiredMobileNumberLength: [9, 10],
                sort:'I',
            },
            // {
            //     FlagImage: '/assets/images/svgs/flags/ph.svg',
            //     CountryName: 'Philippines',
            //     MobileCode: '+63',
            //     RequiredMobileNumberLength: [10]
            // },
            {
                FlagImage: '/assets/images/svgs/flags/gb.svg',
                CountryName: '英國',
                MobileCode: '+44',
                RequiredMobileNumberLength: [11],
                sort:'U',
            },
            {
                FlagImage: '/assets/images/svgs/flags/vn.svg',
                CountryName: '越南',
                MobileCode: '+84',
                RequiredMobileNumberLength: [12],
                sort:'V',
            },
            {
                FlagImage: '/assets/images/svgs/flags/South_Korea.svg',
                CountryName: '韓國',
                MobileCode: '+82',
                RequiredMobileNumberLength: [10,11],
                sort:'S',
            },
            {
                FlagImage: '/assets/images/svgs/flags/United_States.svg',
                CountryName: '美國',
                MobileCode: '+1',
                RequiredMobileNumberLength: [10],
                sort:'U',
            },
            {
                FlagImage: '/assets/images/svgs/flags/Taiwan.svg',
                CountryName: '台灣',
                MobileCode: '+886',
                RequiredMobileNumberLength: [8,9,10],
                sort:'T',
            },
            {
                FlagImage: '/assets/images/svgs/flags/Thailand.svg',
                CountryName: '泰國',
                MobileCode: '+66',
                RequiredMobileNumberLength: [9,10],
                sort:'T',
            },
            {
                FlagImage: '/assets/images/svgs/flags/cn.svg',
                CountryName: '中國',
                MobileCode: '+86',
                RequiredMobileNumberLength: [11],
                sort:'C',
            },
            {
                FlagImage: '/assets/images/svgs/flags/Malaysia.svg',
                CountryName: '馬來西亞',
                MobileCode: '+60',
                RequiredMobileNumberLength: [9,10],
                sort:'M',
            },
        ],
        countryList: [
            {
                FlagImage: '/assets/images/svgs/flags/au.svg',
                CountryName: 'Australia',
                CountryCode: 'AU'
            },
            {
                FlagImage: '/assets/images/svgs/flags/hk.svg',
                CountryName: '中國香港特別行政區',
                CountryCode: 'HK'
            },
            {
                FlagImage: '/assets/images/svgs/flags/it.svg',
                CountryName: 'Italy',
                CountryCode: 'IT'
            },
            {
                FlagImage: '/assets/images/svgs/flags/ph.svg',
                CountryName: 'Philippines',
                CountryCode: 'PH'
            },
            {
                FlagImage: '/assets/images/svgs/flags/gb.svg',
                CountryName: 'United Kingdom',
                CountryCode: 'GB'
            },
            {
                FlagImage: '/assets/images/svgs/flags/vn.svg',
                CountryName: 'Vietnam',
                CountryCode: 'VN'
            }
        ],
        genderList: [
            {Label: '男', Value: 'Male'},
            {Label: '女', Value: 'Female'},
            // {Label: 'Prefer not to say', Value: 'Prefer not to say'},
            {Label: 'Other', Value: 'Other'}
        ],
        social_media_status:[
            {label:'活躍中',value:0},
            {label:'停用中',value:1},
        ],
        mission_status:[
            {label:'草稿',value:'Draft'},
            {label:'新建',value:'New'},
            {label:'開放中',value:'Open'},
            {label:'已完結',value:'Closed'},
            {label:'已取消',value:'Cancelled'},
        ],
        mission_status_new:[
            {label:'已取消',value:'m-s-0'},
            {label:'草稿',value:'m-s-1'},
            {label:'審批中',value:'m-s-2'},
            {label:'已發佈',value:'m-s-3'},
            {label:'已完結',value:'m-s-4'},
            {label:'已過期',value:'m-s-5'},
        ],

        mission_status_create:[
            {label:'草稿',value:'m-s-1'},
            {label:'審批中',value:'m-s-2'},
            {label:'已發佈',value:'m-s-3'},
        ],
        mission_status_edit:[
            {label:'草稿',value:'m-s-1'},
            {label:'已發佈',value:'m-s-3'},
            {label:'已取消',value:'m-s-0'},
        ],
        mission_earn_currency:[
            {label:'HKD',value:'HKD'},
        ],
        mission_type:[
            {label:'活動',value:'m-t-1'},
            {label:'直播',value:'m-t-2'},
            {label:'帖子',value:'m-t-3'},
            {label:'影片',value:'m-t-4'},
        ],

        mission_status_list:{
            'm-s-0':'已取消',
            'm-s-1':'草稿',
            'm-s-2':'審批中',
            'm-s-3':'已發佈',
            'm-s-4':'已完結',
            'm-s-5':'已過期',
        },

        mission_type_list:{
            'm-t-1':'活動',
            'm-t-2':'直播',
            'm-t-3':'帖子',
            'm-t-4':'影片',
        },

        wt_type:{
            'wt-t-1':'登記',
            'wt-t-2':'推薦',
            'wt-t-3':'任務',
            'wt-t-4':'提取',
            'wt-t-100':'提取',
        },

        wt_status:{
            'wt-s-1':'成功',
            'wt-s-2':'待定',
            'wt-s-3':'拒绝',
            'wt-s-4':'成功',
        },

        transaction_status:[
            {label:'Settled',value:'wt-s-1'},
            {label:'Processing',value:'wt-s-2'},
            {label:'Rejected',value:'wt-s-3'},
            // {label:'Settled',value:'wt-s-4'},
        ],

        mission_status_tab:[
            {label:'進行中',value:'m-s-3'},
            {label:'已過期',value:'m-s-5'},
            {label:'全部',value:''},
        ],
        influencer_job_status:[
            {label:'審核中',value:'Under Review'},
            {label:'已通過',value:'Approved'},
            {label:'未通過',value:'Rejected'},
            {label:'已參加',value:'Attended'},
            {label:'已逾期',value:'Closed'},
        ],
        influencer_job_status_tab:[
            {label:'全部',value:''},
            {label:'審核中',value:'j-s-1'},
            {label:'已通過',value:'j-s-2'},
            {label:'未通過',value:'j-s-3'},
            {label:'已完結',value:'j-s-5'},
        ],
        influencer_job_status_new:[
            {label:'審核中',value:'j-s-1'},
            {label:'已通過',value:'j-s-2'},
            {label:'未通過',value:'j-s-3'},
            {label:'已撤回',value:'j-s-4'},
            {label:'已完結',value:'j-s-5'},
            {label:'已獎勵',value:'j-s-6'},
        ],
        influencer_job_status_new_list:[
            {label:'全部',value:''},
            {label:'審核中',value:'j-s-1',type:'jl_job_status'},
            {label:'已通過',value:'j-s-2',type:'jl_job_status'},
            {label:'未通過',value:'j-s-3',type:'jl_job_status'},
            {label:'已撤回',value:'j-s-4',type:'jl_job_status'},
            {label:'已完結',value:'j-s-5',type:'jl_job_status'},
            {label:'已獎勵',value:'j-s-6',type:'jl_job_status'},
            // {label:'已逾期',value:'j-s-5',type:'mission_status'},
            // {label:'已取消',value:'j-s-6',type:'mission_status'},
        ],
        transaction_status_new_list:[
            {label:'All',value:'',type:'wt_type_new'},
            {label:'Processing Withdrawal',value:'wt-ts-1',type:'wt_type_new'},
            {label:'Settled Withdrawal',value:'wt-ts-2',type:'wt_type_new'},
            {label:'Rejected Withdrawal',value:'wt-ts-3',type:'wt_type_new'},
            {label:'Rewarded',value:'wt-ts-4',type:'wt_type_new'},
        ],
        admin_vet_status:[
            {label:'審核中',value:'Under Review'},
            {label:'已通過',value:'Approved'},
            {label:'未通過',value:'Rejected'},
        ],
        admin_vet_status_new:[
            {label:'審核中',value:'Under Review'},
            {label:'已通過',value:'Approved'},
            {label:'未通過',value:'Rejected'},
            {label:'已撤回',value:'Withdraw'},
        ],
        adminDecisionList: [
            {"Label": "審核中", "Value": "UNDER_REVIEW"},
            {"Label": "已通過", "Value": "APPROVED"},
            {"Label": "未通過", "Value": "REJECTED"},
            {"Label": "已撤回", "Value": "WITHDRAW"}
        ],
        user_status:[
            {label:'活躍中',value:1},
            {label:'停用中',value:0},
        ],

        //新增
        kol_tier:[
            {label:'Bronze',value:'1',ig_flollowers:'2,000-9,999'},
            {label:'Silver',value:'2',ig_flollowers:'10,000-19,999'},
            {label:'Gold',value:'3',ig_flollowers:'20,000-29,999'},
            {label:'Diamond',value:'4',ig_flollowers:'30,000+'},
        ],
        admin_kol_tier:[
            {label:'Unqualified',value:'0'},
            {label:'Bronze',value:'1'},
            {label:'Silver',value:'2'},
            {label:'Gold',value:'3'},
            {label:'Diamond',value:'4'},
        ],
        user_status_list:[
            {label:'活躍中',value:'1'},
            {label:'停用',value:'0'},
            {label:'所有用戶',value:''},
        ],
        langList:[
            {label:'English',value:'en'},
            {label:'繁體中文',value:'tc'},
            {label:'其他',value:'others'},
        ],
        langListNoOther:[
            {label:'English',value:'en'},
            {label:'繁體中文',value:'tc'},
        ],

        operation_code_list:{
            'u-bol-0': '註冊',
            'u-bol-0-1': '註冊 By TikTok',
            'u-bol-0-2': '註冊 By Instagram',
            'u-bol-1': '添加用戶',
            'u-bol-2': '個人資料更新',
            'u-bol-4': '登入',
            'u-bol-5': '用戶啟用',
            'u-bol-6': '用戶停用',
        },

        'u-bol-0': '註冊',
        'u-bol-0-1': '註冊 By TikTok',
        'u-bol-0-2': '註冊 By Instagram',
        'u-bol-1': '添加用戶',
        'u-bol-2': '個人資料更新',
        'u-bol-4': '登入',
        'u-bol-5': '用戶啟用',
        'u-bol-6': '用戶停用',

        mission_admin_searh_status_list:{
            'active':'活躍',
            'expired':'已過期',
            'submitted':'審批中',
            'draft':'草稿',
            'all':'全部',
        },
    }
};
