/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const missionRoutes = {
    path: '/mission',
    component: Layout,
    redirect: '/mission/list',
    name:'Mission List',
    meta: { title: 'mission_list', icon: 'peoples', permissions: ['influencer_mission']},
    children: [
        {
            path: 'list',
            component: () => import('@/views/Mission/Index'),
            name: 'MissionList',
            meta: { title: 'mission_list', icon: '/assets/images/svgs/icons/mission.svg', permissions: ['influencer_mission']},
        },
    ],
};


export default missionRoutes;
