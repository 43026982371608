/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const roleRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/role',
    name:'Role List',
    meta: { title: 'role', icon: 'peoples'/*, permissions: ['mission_list']*/},
    children: [
        {
            path: 'role',
            component: () => import('@/views_admin/Role/Index'),
            name: 'RoleList',
            meta: { title: 'role', icon: '/assets/images/svgs/icons/role-add.svg', permissions: ['admin_role_list']},
        },
    ],
};


export default roleRoutes;
