export default {
    common: {
        "sign_up": "Sign up",
        "login": "Login",
        "log_in": "Log in",
        "register": "Register",
        "logout": "Logout",
        "profile": "Profile",
        "step": "Step",
        "type": "Type",
        "value": "Value",
        "cancel": "Cancel",
        "upload": "Upload",
        "close": "Close",
        "update": "Update",
        "submit": "Submit",
        "continue": "Continue",
        "add": "Add",
        "remove": "Remove",
        "save": "Save",
        "edit": "Edit",
        "back": "Back",
        "status": "Status",
        "copy": "Copy",
        "details": "Details",
        "assign": "Assign",
        "password": "Password",
        "user_id": "User ID",
        "contact": "Contact",
        "address": "Address",
        "address_1": "13103N, ATL Logistics Centre B, Berth 3, Kwai Chung Container Terminals",
        "qr_reader": "QR Reader",
        "register_text_1": "By clicking register, you confirm that you have read and accepted our company's",
        "terms_conditions": "Terms & Conditions",
        "terms_and_conditions": "Terms and Conditions",
        "privacy_policy": "Privacy Policy",
        "please_select": "Please select",
        "select": "Select",
        "must_be_over_18_years_old": "Must be over 18 years old",
        "choose_picture": "Choose picture",
        "instagram_tooltip_text_1": "In order to be eligible to apply for Missions and for your referral to earn a referral bonus, it is required that your Instagram account be either a Creator or Business account.",
        "instagram_tooltip_text_2": "How to switch to Creator account? Learn",
        "instagram_tooltip_text_3": "How to switch to Business account? Learn",
        "instagram_tooltip_text_4": "here",
        "wallet_balance_tooltip_1": "Wallet Balance:",
        "wallet_balance_tooltip_2": "Your wallet balance will be updated within 14 business days upon any action taken.",
        "wallet_balance_tooltip_3": "You can request to withdraw your balance to your bank account once it reaches HKD 300.",
        "wallet_balance_tooltip_4": "Wallet Balance",
        "wallet_balance_tooltip_5": "",
        "wallet_balance_tooltip_6": "",
        "change_password":"Change Password",
        "reset_password":"Reset Password",
    }
};
