/** When your routing table is too long, you can split it into small modules**/
import Layout from '@/layout';

const scanRoutes = {
    path: '/admin',
    component: Layout,
    redirect: '/admin/scan',
    name:'Scan List',
    meta: { title: 'scan_list', icon: 'peoples', permissions: ['admin_scan_qr']},
    children: [
        {
            path: 'scan',
            component: () => import('@/views_admin/Scan/Index'),
            name: 'ScanList',
            meta: { title: 'scan_list', icon: '/assets/images/svgs/icons/scan.svg', permissions: ['admin_scan_qr']},
        },
        {
            path: 'scan-detail',
            component: () => import('@/views_admin/Scan/Detail'),
            name: 'ScanDetail',
            hidden: true,
            meta: { title: 'scan_detail', /*, permissions: ['mission_list']*/},
        },
        {
            path: 'scan-camera',
            component: () => import('@/views_admin/Scan/ScanCamera'),
            name: 'ScanCameraPage',
            hidden: true,
            meta: { title: 'scan_camera', /*, permissions: ['mission_list']*/},
        },
    ],
};


export default scanRoutes;
