export default {
    role: {
        permission_name:"Permission Name",
        select_all:"Select all",

        /*influencer*/
        influencer_mission:'Missions',
        influencer_mission_apply:'Apply now [Operation]',
        influencer_job:"My Jobs",
        influencer_profile:"Profile",
        influencer_invite_friends:"Invite Friends",
        user_submit_instagram_profile:"Update Instagram Username [Operation]",
        user_instagram_type_personal_handling:"User Instagram Type Personal Handling",

        /*admin*/
        admin_dashboard:"Dashboard",
        admin_analysis:"Analysis",
        admin_social_media:"Social Media",
        admin_social_media_list:"Social Media",
        admin_social_media_create:"Social Media Create",
        admin_social_media_update:"Social Media Update [Operation]",
        admin_mission:"Missions",
        admin_mission_list:"Missions",
        admin_mission_create:"Missions Create",
        admin_mission_update:"Missions Update",
        admin_job:"Vetting Job",
        admin_job_list:"Vetting Job",
        admin_job_update_status:"Admin Decisions [Operation]",
        admin_brand:"Brands",
        admin_brand_list:"Brands",
        admin_brand_create:"Brands Create",
        admin_brand_update:"Brands Update",
        admin_brand_dropdown_menu:"Brands Dropdown Menu",
        admin_user:"Users",
        admin_user_list:"Users",
        admin_user_create:"Users Create [Operation]",
        admin_user_update:"Users Update",
        user_scrape_instagram_profile:"Users Scrape Instagram Profile [Operation]",
        admin_role:"Role",
        admin_role_list:"Role",
        admin_scan_qr:"Scan QR code",

        assign_user_role:"Assign user to role",
        remove_role:"Remove Role",
        add_role:"Add Role",
        admin_user_transaction_details:"Transaction Details",
        admin_user_banlance_withdraw:"Balance Withdrawal",
        admin_user_export:'Export',
        admin_transaction:"Transaction",
        admin_transaction_status:"Transaction Status",

    }
};
