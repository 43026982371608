import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';// element-ui lang
import elementTCLocale from 'element-ui/lib/locale/lang/zh-TW';// element-ui lang

import enMessage from './en/message';
import zhMessage from './zh/message';
import tcMessage from './tc/message';

import enConfig from './en/config';
import zhConfig from './zh/config';
import tcConfig from './tc/config';

import enMenu from './en/menu';
import zhMenu from './zh/menu';
import tcMenu from './tc/menu';

import enRole from './en/role';
import zhRole from './zh/role';
import tcRole from './tc/role';

import enPage from './en/page';
import zhPage from './zh/page';
import tcPage from './tc/page';

import enHome from './en/home';
import zhHome from './zh/home';
import tcHome from './tc/home';

import enCommon from './en/common';
import zhCommon from './zh/common';
import tcCommon from './tc/common';

import enAuth from './en/auth';
import zhAuth from './zh/auth';
import tcAuth from './tc/auth';

import enStatus from './en/status';
import zhStatus from './zh/status';
import tcStatus from './tc/status';

import en404 from './en/404';
import zh404 from './zh/404';
import tc404 from './tc/404';

Vue.use(VueI18n);

const messages = {
    en: {
        ...elementEnLocale,
        ...enMessage,
        ...enConfig,
        ...enMenu,
        ...enRole,
        ...enPage,
        ...enHome,
        ...enCommon,
        ...enAuth,
        ...enStatus,
        ...en404,
    },
    zh: {
        ...elementZhLocale,
        ...zhMessage,
        ...zhConfig,
        ...zhMenu,
        ...zhRole,
        ...zhPage,
        ...zhHome,
        ...zhCommon,
        ...zhAuth,
        ...zhStatus,
        ...zh404,
    },
    tc: {
        ...elementTCLocale,
        ...tcMessage,
        ...tcConfig,
        ...tcMenu,
        ...tcRole,
        ...tcPage,
        ...tcHome,
        ...tcCommon,
        ...tcAuth,
        ...tcStatus,
        ...tc404,
    },
};

export function getLanguage() {
    const chooseLanguage = Cookies.get('language');
    if (chooseLanguage) {
        return chooseLanguage;
    }

    // if has not choose language
    let language = process.env.VUE_APP_DEFAULT_LANG || 'en';
    const locales = Object.keys(messages);
    for (const locale of locales) {
        if (language.indexOf(locale) > -1) {
            return locale;
        }
    }
    console.log(locales)
    return 'en';
}
const i18n = new VueI18n({
    // set locale
    // options: en | ru | vi | zh
    locale: getLanguage(),
    // set locale messages
    messages,
});

export default i18n;
